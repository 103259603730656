import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  useTheme,
  Text,
  TextInput,
  Button,
  Divider,
} from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import Switch from "../components/input/Switch";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import CustomPicker from "../components/input/CustomPicker";
import { eventActivityTypes } from "../constants/activityTypes";
import DistanceInput from "../components/input/DistanceInput";
import {
  userUnitsToMeters,
  metersToUserUnits,
} from "../components/utils/distance";
import { getLocalStartOfDayForDate } from "../components/utils/dateUtils";
import { EventData } from "../types/event";
import { createEvent, updateEvent } from "../api/events";
import { useProfile } from "../provider/ProfileProvider";
import { useSchedule } from "../provider/ScheduleProvider";
import { updateScheduleForDates } from "../provider/ScheduleProvider/actions";
import { getCurrentViewUserId } from "../provider/ProfileProvider/selectors";
import { SCREEN_NAMES } from "../constants/screenNames";
import { Picker } from "@react-native-picker/picker";
import {
  RunEventDistance,
  RunEventDistances,
  RunEventDistancesList,
} from "../types/activity";
import EventTypeAndDistanceForm from "../components/Event/EditEventTypeAndDistance";

type EditEventProps = NativeStackScreenProps<
  MainStackParamList,
  "Edit Event"
> & { isDuplicateMode?: boolean }; // Add isDuplicateMode prop

const EditEvent: React.FC<EditEventProps> = ({ navigation, route }) => {
  const { state, dispatch } = useSchedule();
  const { state: profileState } = useProfile();
  // change this in appbar to just pass Event id into route.params and get the Event data from state
  const { event, isDuplicateMode } = route.params as {
    event: EventData;
    isDuplicateMode: boolean;
  };
  // this screen handles create, update, duplicate activities and copy from workout library
  const isEditMode = !!event.id;
  // replace Event from route.params with Event from state

  // Initialize EventData as an empty object
  let eventData: EventData = {};
  // Initialize originalEventDateStr as null - used to store the original event date for editing an existing event and updating the state
  let originalEventDateStr: string | null = null;

  if (event && event.id) {
    // Assign values to eventData for editing an existing Event
    eventData = event;
    originalEventDateStr = getLocalStartOfDayForDate(
      new Date(event.event_date)
    );
  }

  if (isDuplicateMode && event) {
    // Assign values to eventData for duplicating an Event
    eventData = event;
  }

  const [title, setTitle] = useState("Race");
  const [description, setDescription] = useState("");
  const [selectedType, setSelectedType] = useState<number | null>(1);
  // if state.focusDate is defined then set eventDate to state.focusDate otherwise use today's date
  const [eventDate, setEventDate] = useState(
    state.focusDate ? new Date(state.focusDate) : new Date()
  );
  const [totalTimePlanned, setTotalTimePlanned] = useState<number | null>(
    isEditMode || isDuplicateMode ? eventData.total_time_planned || null : null
  );
  const [totalDistancePlanned, setTotalDistancePlanned] = useState<
    number | null
  >(10);
  const [isCompleted, setIsCompleted] = useState<boolean>(false); // Add isCompleted state
  const [error, setError] = useState<string>("");
  const [selectedRunEventDistance, setSelectedRunEventDistance] =
    useState<RunEventDistance | null>(null);

  useEffect(() => {
    console.log("EditEvent useEffect eventData:", eventData);
    if (isEditMode) {
      setTitle(eventData.title || "Race");
      setDescription(eventData.description || "");
      setSelectedType(eventData.type || 1);
      setEventDate(new Date(eventData.event_date) || new Date());
      setTotalTimePlanned(eventData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        eventData.total_distance_planned
          ? metersToUserUnits(eventData.total_distance_planned)
          : null
      );
      setIsCompleted(!!eventData.is_completed); // Set isCompleted value
    }
    if (isDuplicateMode) {
      // only pre-populate planned Event data if we are duplicating an Event
      setTitle(eventData.title || "Race");
      setDescription(eventData.description || "");
      setSelectedType(eventData.type || 1);
      setEventDate(new Date(eventData.event_date) || new Date());
      setTotalTimePlanned(eventData.total_time_planned || null);
      // Convert meters to user units before storing in state
      setTotalDistancePlanned(
        eventData.total_distance_planned
          ? metersToUserUnits(eventData.total_distance_planned)
          : null
      );
    }
  }, [eventData]);

  const handleSaveEvent = async () => {
    if (!selectedType) {
      setError("Please select a type");
      return;
    }

    try {
      const user_id = getCurrentViewUserId(profileState); // (await supabase.auth.getSession()).data.session?.user.id;
      const utcOffset = new Date().getTimezoneOffset() * -60;
      const eventDateStr = getLocalStartOfDayForDate(eventDate);
      const startedAtUtc = new Date(eventDate);
      startedAtUtc.setSeconds(startedAtUtc.getSeconds() - utcOffset);

      const newEvent = {
        user_id,
        title,
        description,
        type: selectedType,
        event_date: eventDateStr,
        utc_offset: utcOffset,
        total_time_planned: totalTimePlanned,
        total_distance_planned: userUnitsToMeters(totalDistancePlanned), // Convert user units to meters before storing in DB
        is_completed: isCompleted, // Include is_completed field
      };

      if (isEditMode && eventData.id) {
        const updatedEvent = {
          ...newEvent,
          id: eventData.id,
        };
        // Update existing Event
        const { data, error } = await updateEvent(updatedEvent);

        if (error) {
          throw error;
        }
      } else {
        // Create new Event
        const { data, error } = await createEvent(newEvent);

        if (error) {
          throw error;
        }
      }
      // set state to fetch schedule for this event date after saving
      updateScheduleForDates(dispatch, user_id, eventDateStr);
      // if original event date is different from the new event date then update the schedule for the original workout date too
      if (originalEventDateStr && originalEventDateStr !== eventDateStr) {
        updateScheduleForDates(dispatch, user_id, originalEventDateStr);
      }
      alert(
        isDuplicateMode
          ? "Event duplicated successfully!"
          : isEditMode
          ? "Event updated successfully!"
          : "Event saved successfully!"
      );
      navigation.goBack();
    } catch (error) {
      console.error("Error saving Event:", error);
    }
  };

  const handleDistanceChange = (
    totalDistance: number | null,
    isCompleted: boolean
  ) => {
    if (isCompleted) {
      setTotalDistanceCompleted(totalDistance);
    } else {
      setTotalDistancePlanned(totalDistance);
    }
  };

  const handleDateChange = (date: Date) => {
    setEventDate(date);
  };

  return (
    <ScreenWrapper withForms={true}>
      <TextInput
        label="Title"
        value={title}
        onChangeText={(text) => setTitle(text)}
        style={styles.input}
      />
      <DatePickerInput
        locale="en-GB"
        label="Date"
        value={eventDate}
        onChange={(d) => handleDateChange(d)}
        inputMode="start"
        style={styles.input}
      />
      <Divider />
      <EventTypeAndDistanceForm
        selectedEventActivityType={selectedType}
        setSelectedEventActivityType={setSelectedType}
        selectedEventDistance={selectedRunEventDistance}
        setSelectedEventDistance={setSelectedRunEventDistance}
        distanceInMeters={totalDistancePlanned}
        setDistanceInMeters={setTotalDistancePlanned}
      />
      <Divider />
      <CustomPicker
        title="Sport type"
        options={eventActivityTypes}
        selectedValue={selectedType}
        onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
      />
      <CustomPicker
        title="Event distance"
        options={RunEventDistancesList}
        selectedValue={selectedRunEventDistance}
        onValueChange={(itemValue) => setSelectedRunEventDistance(itemValue)}
      />
      {/* <Picker
        selectedValue={selectedRunEventDistance}
        onValueChange={(itemValue) => setSelectedRunEventDistance(itemValue)}
        style={styles.picker}
      >
        {Object.values(RunEventDistances).map((distance) => (
          <Picker.Item
            key={distance}
            label={RunEventDistanceData[distance].label}
            value={distance}
          />
        ))}
      </Picker> */}
      {/* <DistanceInput
        value={totalDistancePlanned}
        onDistanceChange={(value) => handleDistanceChange(value, false)}
      /> */}
      <TextInput
        label="Description"
        value={description}
        onChangeText={(text) => setDescription(text)}
        multiline={true}
        numberOfLines={6}
        style={{ minHeight: 100 }}
        style={styles.input}
      />
      <Divider />
      <List.Section>
        <Switch
          value={isCompleted}
          onValueChange={(value) => setIsCompleted(value)}
          label="Completed"
          switchProps={{
            color: "green", // Customize the switch color here
          }}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <List.Item
          title="Goals"
          right={(props) => <List.Icon {...props} icon="plus" />}
          onPress={() => {
            // Handle goals addition
          }}
        />
      </List.Section>
      <View style={styles.row}>
        <Button mode="contained" onPress={handleSaveEvent}>
          {isDuplicateMode
            ? "Duplicate Event"
            : isEditMode
            ? "Update Event"
            : "Add Event"}
        </Button>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
    // marginBottom: 12,
  },

  subheading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },

  row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
  picker: {
    // height: 50,
    // width: "100%",
  },
});

export default EditEvent;
