import { fetchScheduleInDateRange } from "../../api/schedule";
import { fetchProfile } from "../../api/users";
import { ProfileAction, ProfileActionTypes } from "./types";
import {
  fetchMyAthleteProfiles,
  fetchMyCoachProfiles,
} from "../../api/coaches";

export function setLoading(isLoadingProfile: boolean) {
  return { type: ProfileActionTypes.SET_LOADING, isLoadingProfile };
}

interface Dispatch {
  (action: ProfileAction): void;
}

// fetches schedule object for user_id in the date range of +/- 90 days from today and sets the global state.schedule object
export const fetchInitialProfile = async (
  dispatch: Dispatch,
  user_id?: string
): Promise<void> => {
  try {
    dispatch({ type: ProfileActionTypes.FETCH_PROFILE });

    const profile = await fetchProfile(); // Call the utility function to fetch the profile

    dispatch({
      type: ProfileActionTypes.FETCH_PROFILE_SUCCESS,
      profile,
    });
  } catch (error) {
    console.error("Error fetching initial user profile:", error);
    dispatch({ type: ProfileActionTypes.FETCH_PROFILE_FAILURE, error });
  }
};

export const fetchCoachedAthleteData = async (
  dispatch: Dispatch,
  user_id?: string
): Promise<void> => {
  try {
    dispatch({ type: ProfileActionTypes.FETCH_ATHLETES });

    // Fetch athlete data from Supabase
    const athletes = await fetchMyAthleteProfiles();

    dispatch({
      type: ProfileActionTypes.FETCH_ATHLETES_SUCCESS,
      athletes,
    });
  } catch (error) {
    console.error("Error fetching initial user profile:", error);
    dispatch({ type: ProfileActionTypes.FETCH_ATHLETES_FAILURE, error });
  }
};

export const fetchCoachesData = async (
  dispatch: Dispatch,
  user_id?: string
): Promise<void> => {
  try {
    dispatch({ type: ProfileActionTypes.FETCH_COACHES });

    // Fetch athlete data from Supabase
    const coaches = await fetchMyCoachProfiles();

    dispatch({
      type: ProfileActionTypes.FETCH_COACHES_SUCCESS,
      coaches,
    });
  } catch (error) {
    console.error("Error fetching initial user profile:", error);
    dispatch({ type: ProfileActionTypes.FETCH_COACHES_FAILURE, error });
  }
};

// fetches schedule object for user_id in the date range of +/- 90 days from today and sets the global state.schedule object
export const setFetchWorkoutLibraryRequired = (
  dispatch: Dispatch,
  fetchWorkoutLibraryRequired: boolean
) => {
  try {
    dispatch({
      type: ProfileActionTypes.SET_FETCH_WORKOUT_LIBRARY_REQUIRED,
      fetchWorkoutLibraryRequired,
    });
  } catch (error) {
    console.error("Error setting fetchWorkoutLibraryRequired:", error);
  }
};
