import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Card,
  Paragraph,
  Avatar,
  Text,
  ThemeProvider,
  Icon,
} from "react-native-paper";
import { ICONS } from "../../constants/icons";
import { formatDuration } from "../utils/time";
import { formatDateShortWithDayOfWeek } from "../utils/dateUtils";
import { metersToFormattedUserUnits } from "../utils/distance";
import EventCardTitle from "./EventCardTitle";
import { useTheme } from "react-native-paper";
import { EventData } from "../../types/event";
import { checkEventHasAttachments } from "../../api/events";
import { has } from "lodash";
import { fetchThumbnail } from "../../api/storage";

interface EventCardProps {
  event: EventData;
}

const EventCard: React.FC<EventCardProps> = ({
  event, // Add isCompleted prop
}) => {
  const [hasAttachment, setHasAttachment] = useState(false);
  const [hasCoverImage, setHasCoverImage] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const checkForAttachments = async () => {
      const checkHasAttachment = await checkEventHasAttachments(
        event.id,
        event.user_id
      );
      setHasAttachment(checkHasAttachment);
    };
    checkForAttachments();
  }, [event]);

  useEffect(() => {
    const fetchThumbnailUrl = async () => {
      try {
        const filePath = `${event.user_id}/${event.id}/attachments/${event.cover_image}`;
        const url = await fetchThumbnail("events", filePath, 200, 200);
        setThumbnailUrl(url);
      } catch (error) {
        console.error("Error fetching thumbnail:", error);
      }
    };
    if (event.cover_image) {
      setHasCoverImage(true);
      fetchThumbnailUrl();
    } else {
      setHasCoverImage(false);
    }
  }, [event]);

  return (
    <Card style={{ width: "100%" }}>
      {hasCoverImage && thumbnailUrl && (
        <Card.Cover source={{ uri: thumbnailUrl }} />
      )}
      <EventCardTitle
        title={event.title}
        activityTypeEnum={event.type}
        isCompleted={event.is_completed}
        eventDate={event.event_date}
      />
      <Card.Content>
        {event.description && event.description.length > 0 && (
          <Paragraph numberOfLines={3}>{event.description}</Paragraph>
        )}
        <View style={styles.detailsContainer}>
          <View style={styles.durationContainer}>
            <Icon source={ICONS.EVENT_DATE} size={24} />
            <Text variant="bodyLarge" style={styles.metricText}>
              {formatDateShortWithDayOfWeek(new Date(event.event_date))}
            </Text>
          </View>
          {hasAttachment && (
            <View style={styles.durationContainer}>
              <Icon source={ICONS.ATTACHMENT} size={24} />
            </View>
          )}
          {event.event_checklists && (
            <View style={styles.durationContainer}>
              <Icon
                source={ICONS.EVENT_CHECKLIST}
                color={theme.colors.onSurface}
                size={24}
              />
              <Text variant="bodyLarge" style={styles.metricText}>
                0/3
              </Text>
            </View>
          )}
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  durationContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
    opacity: 0.8,
  },
  metricText: {
    opacity: 0.8,
  },
  type: {
    fontSize: 12,
  },
});

export default EventCard;
