import React, { useEffect, useState } from "react";
import { Card, Text, ToggleButton } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import TotalsDataTable from "./TotalsDataTable";
import { types as activityTypes } from "../constants/activityTypes";
import BarChart from "./charts/WeeklyBarChart";
import { convertDailyTotalsObjectToArray } from "./utils/activityUtils";
import TotalTypeSelect from "./TotalTypeSelect";
import { getStartOfWeekForDate, isThisWeek } from "./utils/dateUtils";
import { TotalType } from "../types/activity";
import WeekSelector from "./Insights/WeekSelector";
import { useSchedule } from "../provider/ScheduleProvider";
import { calculateWorkoutWeeklyAndDailyTotalsPerActivityForWeek } from "../provider/ScheduleProvider/selectors";

const TotalsCard = () => {
  const [selectedKey, setSelectedKey] = useState<string>("total");
  const [selectedTotalType, setSelectedTotalType] = useState<TotalType>(
    TotalType.DURATION
  );
  const [selectedWeekStart, setSelectedWeekStart] = useState(
    getStartOfWeekForDate(new Date()) // Initialize with the start of the current week
  );
  const { state, dispatch } = useSchedule();
  const totals = calculateWorkoutWeeklyAndDailyTotalsPerActivityForWeek(
    state.schedule,
    selectedWeekStart
  );

  // TODO: handle when selected week is out of schedule range, dispatch an action to extend the schedule range

  if (!totals || !totals[selectedKey]) {
    // Handle the case when totals or the selected key is invalid
    return (
      <Card style={styles.container}>
        <Card.Content>
          <Text>
            No data. Add some training sessions in the Schedule tab and connect
            Strava for tracking completed activities...
          </Text>
        </Card.Content>
      </Card>
    );
  }

  const toggleButtons = Object.keys(totals).map((key, index) => (
    <ToggleButton
      key={index}
      icon={
        key === "total"
          ? "chart-bar-stacked"
          : activityTypes.find((type) => type.label === key)?.icon ||
            "format-align-left"
      }
      value={key}
    />
  ));

  const handleToggle = (value: string) => {
    // The ToggleButton.Row value appeasr to be null if the selected button is selected again?
    // To fix this, only update selectedKey if value is not null
    if (value !== null) {
      setSelectedKey(value);
    }
  };

  // Callback function to handle the selected value
  const handleTotalTypeSelect = (value: TotalType) => {
    setSelectedTotalType(value);
  };

  return (
    <Card style={styles.container}>
      <WeekSelector
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
      />
      <Card.Content>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        ></View>
        <ToggleButton.Row
          onValueChange={(value) => handleToggle(value)}
          value={selectedKey}
          style={styles.toggleButtonRow}
        >
          {toggleButtons}
        </ToggleButton.Row>
        <Text variant="titleMedium" style={styles.summaryTitle}>
          {selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)}
        </Text>
        <TotalTypeSelect onSelect={handleTotalTypeSelect} />
        <BarChart
          data={convertDailyTotalsObjectToArray(totals[selectedKey]["daily"])}
          labelKey="date"
          totalType={selectedTotalType}
        />
        {totals[selectedKey] && (
          <TotalsDataTable totals={totals[selectedKey]} />
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  summaryTitle: {
    marginTop: 8,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    marginHorizontal: 8, // Adjust the spacing as needed
  },
  toggleButtonRow: {
    // justifyContent: "center",
    // marginTop: 10,
  },

  toggleButton: {
    // width: 100,
  },

  selectedToggleButton: {
    // width: 100,
    // backgroundColor: "blue", // Customize the selected button's background color
  },
});

export default TotalsCard;
