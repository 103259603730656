import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import NoteCard from "./NoteCard";
import { NoteData } from "../../types/note";
import { SCREEN_NAMES } from "../../constants/screenNames";

const TouchableNoteCard = ({ note, navigation }) => {
  const handleNotePress = (note: NoteData) => {
    navigation.navigate(SCREEN_NAMES.NOTE_DETAILS, { note });
  };

  return (
    <TouchableOpacity
      style={styles.cardContainer}
      onPress={() => handleNotePress(note)}
    >
      <NoteCard note={note} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: 16,
    width: "100%",
  },
});

export default TouchableNoteCard;
