import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { ScheduleAction, ScheduleState } from "./types";
import scheduleReducer from "./reducer";
import { fetchInitialSchedule } from "./actions";
import { useProfile } from "../ProfileProvider";
import { getCurrentViewUserId } from "../ProfileProvider/selectors";

// Initial state
const initialState: ScheduleState = {
  isLoading: true,
  schedule: {},
  focusDate: new Date().toISOString().split("T")[0],
  error: null,
};

// Create Context
const ScheduleContext = createContext<{
  state: ScheduleState;
  dispatch: React.Dispatch<ScheduleAction>;
} | null>(null);

// Provider component
export const ScheduleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(scheduleReducer, initialState);
  // get coaches view current context user ID
  const { state: profileState } = useProfile();

  useEffect(() => {
    // re-render schedule when coach view changes
    const user_id = getCurrentViewUserId(profileState);
    fetchInitialSchedule(dispatch, user_id);
  }, [profileState.coachView]);

  return (
    <ScheduleContext.Provider value={{ state, dispatch }}>
      {children}
    </ScheduleContext.Provider>
  );
};

// Custom hook for using the Schedule context
export function useSchedule() {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error("useSchedule must be used within a ScheduleProvider");
  }
  return context;
}
