import React from "react";
import { Button, List, useTheme, Text } from "react-native-paper";
import * as Linking from "expo-linking";
import { STRAVA_REDIRECT_URI, STRAVA_CLIENT_ID } from "@env";
import { STRAVA_AUTHORIZATION_URL } from "../../constants/urls";
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  Image,
} from "react-native";
// import { Image } from 'expo-image';

const StravaConnectButton = () => {
  const { colors } = useTheme();
  console.log("STRAVA_REDIRECT_URI", STRAVA_REDIRECT_URI);
  console.log("STRAVA_CLIENT_ID", STRAVA_CLIENT_ID);

  const handlePress = () => {
    // Encode the STRAVA_REDIRECT_URI using the encodeURIComponent function
    const encodedRedirectUri = encodeURIComponent(STRAVA_REDIRECT_URI);
    const authUrl = `${STRAVA_AUTHORIZATION_URL}?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${encodedRedirectUri}&approval_prompt=auto&scope=read_all,activity:read_all`;
    Linking.openURL(authUrl);
  };

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={handlePress}>
        <Image
          style={{ height: 48, width: 193 }}
          source={require("../../../assets/images/btn_strava_connectwith_orange.png")}
          alt="Connect with Strava"
        />
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default StravaConnectButton;
