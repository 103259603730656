import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
// import View from "../components/ThemedView";
import { Card, DataTable, Text, Button } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { formatDuration, formatDate } from "../components/utils/time";
import ScreenWrapper from "../components/ScreenWrapper";
import ActivityCardTitle from "../components/Activity/ActivityCardTitle";
import { getActivityStatusColor } from "../components/utils/activityStatusColor";
import {
  calculateFormatPace,
  calculateFormatUserSpeed,
} from "../components/utils/calculatePace";
import {
  formatDistance,
  metersToUserUnits,
  metersToFormattedUserUnits,
} from "../components/utils/distance";
import { speedOrPaceLabel } from "../components/utils/calculatePace";
import {
  LibraryWorkoutData,
  emptyLibraryWorkoutData,
} from "../types/libraryWorkout";
import ScheduleWorkoutDialog from "../components/WorkoutLibrary/ScheduleWorkoutDialog";

type LibraryWorkoutDetailsProps = NativeStackScreenProps<
  MainStackParamList,
  "Library Workout"
>;

const LibraryWorkoutDetails: React.FC<LibraryWorkoutDetailsProps> = ({
  route,
  navigation,
}) => {
  const { item: libraryWorkout = emptyLibraryWorkoutData } = route.params;
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const formattedDuration = formatDuration(libraryWorkout.total_time_planned);

  useEffect(() => {
    // navigation.setOptions({ title: libraryWorkout.title });
  }, []); //[state.fetchRequired]);

  const handleScheduleWorkout = () => {
    setIsDialogVisible(true);
  };

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <ActivityCardTitle
          title={libraryWorkout.title}
          activityTypeEnum={libraryWorkout.type}
          titleVariant="titleLarge"
        />
        <Text style={styles.description}>{libraryWorkout.description}</Text>
        <Card style={styles.tableContainer}>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title style={styles.centeredCell}></DataTable.Title>
              <DataTable.Title style={styles.centeredCell}>
                Planned
              </DataTable.Title>
            </DataTable.Header>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell}>
                Duration
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {libraryWorkout.total_time_planned !== null
                  ? formatDuration(libraryWorkout.total_time_planned)
                  : "-"}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell}>
                Distance
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {metersToFormattedUserUnits(
                  libraryWorkout.total_distance_planned,
                  1,
                  libraryWorkout.type
                )}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell}>
                {speedOrPaceLabel(libraryWorkout.type)}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {calculateFormatUserSpeed(
                  libraryWorkout.total_distance_planned,
                  libraryWorkout.total_time_planned,
                  libraryWorkout.type
                )}
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </Card>
        <View style={styles.row}>
          <Button mode="contained" onPress={handleScheduleWorkout}>
            Schedule
          </Button>
        </View>
      </View>
      <ScheduleWorkoutDialog
        visible={isDialogVisible}
        hideDialog={() => setIsDialogVisible(false)}
        item={libraryWorkout}
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: 8,
    marginTop: 16,
  },
  row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
});

export default LibraryWorkoutDetails;
