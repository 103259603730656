import React from "react";
import View from "../components/ThemedView";
import { useTheme } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text } from "react-native-paper";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const theme = useTheme();

  return (
    <View>
      <Text>This is the About tab</Text>
    </View>
  );
}
