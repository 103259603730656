import React from "react";
import { Icon, Card, Avatar, MD3TypescaleKey, IconButton } from "react-native-paper";
import { types } from "../../constants/activityTypes";
import { getActivityStatusColor } from "../utils/activityStatusColor";
import { formatDate } from "../utils/time";

interface ActivityCardTitleProps {
  title: string | null;
  activityTypeEnum: number | null;
  isCompleted: boolean | null;
  startedAt: Date | string | null;
  titleVariant?: keyof typeof MD3TypescaleKey;
}

const ActivityCardTitle: React.FC<ActivityCardTitleProps> = ({
  title,
  activityTypeEnum,
  isCompleted = null,
  startedAt = null,
  titleVariant = "bodyLarge",
}) => {
  const getTypeIcon = () => {
    const activityType = types.find((t) => t.value === activityTypeEnum);
    if (activityType) {
      return activityType.icon;
    }
    return "folder";
  };

  const getTypeLabel = () => {
    const activityType = types.find((t) => t.value === activityTypeEnum);
    if (activityType) {
      return activityType.label;
    }
    return "Unknown";
  };

  const getAvatarColor = () => {
    return getActivityStatusColor(isCompleted, startedAt);
  };

  // this component used for workout cards and activity recording cards
  // assume activity recording if isCompleted is null
  // then dont color the title icon
  // and show the startedAt instead of activity type label
  return (
    <Card.Title
      title={title}
      titleVariant={titleVariant}
      subtitle={
        isCompleted !== null ? getTypeLabel() : formatDate(startedAt, true)
      }
      titleNumberOfLines={3}
      left={(props) =>
        isCompleted !== null ? (
          <Avatar.Icon
            {...props}
            icon={getTypeIcon()}
            color="white"
            style={{
              backgroundColor: getAvatarColor(),
            }}
          />
        ) : (
          <Icon {...props} source={getTypeIcon()} />
        )
      }
    />
  );
};

export default ActivityCardTitle;
