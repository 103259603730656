import { SCREEN_NAMES } from "../constants/screenNames";
import { ActivityData } from "./activity";
import { LibraryWorkoutData } from "./libraryWorkout";
import { EventData } from "./event";
import { ScheduledWorkout } from "./schedule";

// Define route names as constants
export const ROUTES = {
  MAIN_TABS: "Main Tabs",
  SETTINGS: "Settings",
  ACCOUNT_SETTINGS: "Account Settings",
  ACTIVITY: "Activity",
  EDIT_ACTIVITY: "Edit Activity",
  EVENT: "Event Details",
  EDIT_EVENT: "Edit Event",
  MERGE_WORKOUTS: "Merge Workouts",
  EDIT_LIBRARY_WORKOUT: "Edit Library Workout",
  MY_ATHLETES: "My Athletes",
  MY_ATHLETE_DETAILS: "My Athlete Details",
  MY_COACHES: "My Coaches",
  ADMIN_TOOLS: "Admin Tools",
} as const;

export type EventParams = {
  event: EventData;
};
export type EditEventParams = EventParams & { isDuplicateMode: boolean };
export type WorkoutParams = {
  activity: ScheduledWorkout;
};
export type EditWorkoutParams = WorkoutParams & { isDuplicateMode: boolean };
export type LibraryWorkoutParams = {
  libraryWorkout: LibraryWorkoutData;
};
export type EditLibraryWorkoutParams = LibraryWorkoutParams & {
  isDuplicateMode: boolean;
};

export type MainStackParamList = {
  [ROUTES.MAIN_TABS]: undefined;
  [ROUTES.SETTINGS]: undefined;
  [ROUTES.ACCOUNT_SETTINGS]: undefined;
  [ROUTES.ACTIVITY]: WorkoutParams;
  [ROUTES.EDIT_ACTIVITY]: EditWorkoutParams;
  [ROUTES.EVENT]: EventParams;
  [ROUTES.EDIT_EVENT]: EditEventParams;
  [ROUTES.MERGE_WORKOUTS]: undefined;
  [ROUTES.EDIT_LIBRARY_WORKOUT]: EditLibraryWorkoutParams;
  [ROUTES.MY_ATHLETES]: undefined;
  [ROUTES.MY_ATHLETE_DETAILS]: undefined;
  [ROUTES.MY_COACHES]: undefined;
  [ROUTES.ADMIN_TOOLS]: undefined;
};

// // Use the constants in your navigation stack
// export type MainStackParamList = {
//   [ROUTES.MAIN_TABS]: undefined;
//   [ROUTES.SETTINGS]: undefined;
//   [ROUTES.ACCOUNT_SETTINGS]: undefined;
//   [ROUTES.EDIT_ACTIVITY]: { activity: ActivityData, isDuplicateMode: boolean };
//   [ROUTES.MERGE_WORKOUTS]: undefined;
//   [ROUTES.MY_ATHLETES]: undefined;
//   [ROUTES.MY_ATHLETE_DETAILS]: undefined;
//   [ROUTES.MY_COACHES]: undefined;
//   [ROUTES.ACTIVITY]: { activity: ActivityData };
//   [ROUTES.EDIT_LIBRARY_WORKOUT]: { libraryWorkout: LibraryWorkoutData, isDuplicateMode: boolean };
// };

// export type MainStackParamList = {
//   MainTabs: undefined;
//   Settings: undefined;
//   "Account Settings": undefined;
//   "Edit Activity": { activity: ActivityData; isDuplicateMode: boolean };
//   "Edit Event": { event: EventData; isDuplicateMode: boolean };
//   "Merge Workouts": undefined;
//   "My Athletes": undefined;
//   "My Athlete Details": undefined;
//   "My Coaches": undefined;
//   Activity: { activity: ActivityData };
//   "Edit Library Workout": {
//     libraryWorkout: LibraryWorkoutData;
//     isDuplicateMode: boolean;
//   };
//   [ROUTES.ADMIN_TOOLS]: undefined;
// };

export type AuthStackParamList = {
  Login: undefined;
  Register: undefined;
  ForgetPassword: undefined;
};

export type MainTabsParamList = {
  Home: undefined;
  Profile: undefined;
  About: undefined;
  Settings: undefined;
  "Connected Apps": undefined;
  "Connect with Strava": undefined;
};
