import * as React from "react";
import { GestureResponderEvent, View, StyleSheet } from "react-native";
import {
  Button,
  Dialog,
  Portal,
  PaperProvider,
  Text,
} from "react-native-paper";
import { sendFetchStravaActivtiesRequestToSupabase } from "../../api/strava";
import { DatePickerInput } from "react-native-paper-dates";
import { useState } from "react";
import Loading from "../../screens/utils/Loading";
import { useSchedule } from "../../provider/ScheduleProvider";
import { fetchInitialSchedule } from "../../provider/ScheduleProvider/actions";

const ImportActivitiesDialog = ({
  visible,
  hideDialog,
}: {
  visible: boolean;
  hideDialog: () => void;
}) => {
  const { state, dispatch } = useSchedule();
  const [loading, setLoading] = useState(false);
  const [imported, setImported] = useState(null);
  // set default to today
  const [before, setBefore] = useState(new Date());
  // set default from as 1 week ago
  const [after, setAfter] = useState(() => {
    const oneWeekAgo = new Date(before);
    oneWeekAgo.setDate(before.getDate() - 7);
    return oneWeekAgo;
  });

  const confirmImportStrava = async () => {
    setLoading(true);
    try {
      const { data, error } = await sendFetchStravaActivtiesRequestToSupabase(
        before,
        after
      );
      if (error) {
        // Handle error
        console.error("Error:", error);
      } else {
        // Handle data
        setImported(data.data);
        setLoading(false);
        alert(
          `${data.data.inserted} activities imported, ${data.data.skipped} skipped.`
        );
        fetchInitialSchedule(dispatch);
      }
    } catch (error) {
      console.error("Error fetching activities from Strava:", error);
      // Handle any errors here
    } finally {
    }
  };

  console.log("ImportActivitiesDialog", { visible, loading, imported });

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>Import Strava activities</Dialog.Title>
        <Dialog.Content>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Text variant="bodyMedium">From</Text>
              <DatePickerInput
                locale="en-GB"
                label="From"
                value={after}
                onChange={(d) => setAfter(d)}
                inputMode="start"
                style={styles.input}
              />
              <Text variant="bodyMedium">To</Text>
              <DatePickerInput
                locale="en-GB"
                label="To"
                value={before}
                onChange={(d) => setBefore(d)}
                inputMode="start"
                style={styles.input}
              />
              {imported && (
                <Text>
                  {imported.inserted} activities imported, {imported.skipped}{" "}
                  skipped.
                </Text>
              )}
            </>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={hideDialog}>Cancel</Button>
          <Button onPress={confirmImportStrava}>
            {loading ? "Importing..." : "Import"}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
  },
});

export default ImportActivitiesDialog;
