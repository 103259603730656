import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Card,
  Paragraph,
  Text,
  Icon,
  Button,
  IconButton,
} from "react-native-paper";
import { ICONS } from "../../constants/icons";
import { useTheme } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { SCREEN_NAMES } from "../../constants/screenNames";

const NoEventCard: React.FC = ({}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  // navigation.navigate(SCREEN_NAMES.EDIT_EVENT, { event: {} })

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate(SCREEN_NAMES.EDIT_EVENT, { event: {} })
      }
      style={styles.eventCardContainer}
    >
      <Card style={{ width: "100%" }}>
        <Card.Title
          title="No upcoming events. Plan your next event now!"
          titleNumberOfLines={3}
          right={(props) => (
            <IconButton
              {...props}
              icon={ICONS.ADD}
              onPress={() =>
                navigation.navigate(SCREEN_NAMES.EDIT_EVENT, { event: {} })
              }
            />
          )}
        />
      </Card>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  eventCardContainer: {
    marginBottom: 8,
    width: "100%",
  },
});

export default NoEventCard;
