import { fetchScheduleInDateRange } from "../../api/schedule";
import { ScheduleAction, ScheduleActionTypes } from "./types";
import { Schedule } from "../../types/schedule";

export function setLoading(isLoading: boolean) {
  return { type: ScheduleActionTypes.SET_LOADING, isLoading };
}

interface Dispatch {
  (action: ScheduleAction): void;
}

// fetches schedule object for user_id in the date range of +/- 90 days from today and sets the global state.schedule object
// TODO: perhaps get the CurrentContextUserId from the ProfileProvider to the fetch is for the correct user
export const fetchInitialSchedule = async (
  dispatch: Dispatch,
  user_id?: string | null
): Promise<void> => {
  try {
    dispatch({ type: ScheduleActionTypes.FETCH_SCHEDULE });

    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 90);

    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 90);

    const { data: schedule }: { data: Schedule } =
      await fetchScheduleInDateRange(user_id, startDate, endDate, true);

    dispatch({
      type: ScheduleActionTypes.FETCH_SCHEDULE_SUCCESS,
      schedule,
    });
  } catch (error) {
    console.error("Error fetching initial schedule:", error);
    dispatch({ type: ScheduleActionTypes.FETCH_SCHEDULE_FAILURE, error });
  }
};

// fetches and schedule object for user_id in the date range of startDate to endDate and extends the current global state.schedule object
export const extendSchedule = async (
  dispatch: Dispatch,
  user_id: string | undefined,
  startDate: Date | string | undefined,
  endDate: Date | string | undefined
) => {
  try {
    dispatch({ type: ScheduleActionTypes.EXTEND_SCHEDULE });

    const { data: schedule } = await fetchScheduleInDateRange(
      user_id,
      startDate,
      endDate
    );

    dispatch({
      type: ScheduleActionTypes.EXTEND_SCHEDULE_SUCCESS,
      schedule,
    });
  } catch (error) {
    console.error("Error extending schedule:", error);
    dispatch({ type: ScheduleActionTypes.EXTEND_SCHEDULE_FAILURE, error });
  }
};

// TODO: update the edge function then this function to handle a single date or a range of dates
export const updateScheduleForDates = async (
  dispatch: Dispatch,
  user_id: string | undefined,
  dates: Date | string | (Date | string)[] // can be a single date or an array of dates
) => {
  try {
    dispatch({ type: ScheduleActionTypes.UPDATE_SCHEDULE });

    // Ensure dates is an array
    if (!Array.isArray(dates)) {
      dates = [dates];
    }

    const schedules: Schedule[] = [];

    for (const date of dates) {
      const { data: schedule }: { data: Schedule } =
        await fetchScheduleInDateRange(user_id, date, date);
      schedules.push(schedule);
    }

    // Combine schedules into one
    const combinedSchedule = schedules.reduce((acc, schedule) => {
      return { ...acc, ...schedule };
    }, {});

    dispatch({
      type: ScheduleActionTypes.UPDATE_SCHEDULE_SUCCESS,
      schedule: combinedSchedule,
    });
  } catch (error) {
    console.error("Error updating schedule for date:", error);
    dispatch({ type: ScheduleActionTypes.UPDATE_SCHEDULE_FAILURE, error });
  }
};
