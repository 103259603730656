import React, { useState } from "react";
import {
  Menu,
  Portal,
  Dialog,
  Paragraph,
  Button,
  Appbar,
} from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ICONS } from "../../constants/icons";
import { supabase } from "../../initSupabase";
import { setFetchWorkoutLibraryRequired } from "../../provider/ProfileProvider/actions";
import { useProfile } from "../../provider/ProfileProvider";

const LibraryWorkoutAppBarMenu = () => {
  const { dispatch } = useProfile();
  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const navigation = useNavigation();
  const route = useRoute();

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const handleEdit = () => {
    closeMenu();
    navigation.navigate("Edit Library Workout", {
      libraryWorkout: route.params.item,
      isDuplicateMode: false,
    });
  };

  const handleDuplicate = () => {
    closeMenu();
    const activityToDuplicate = route.params.item;
    const newActivity = { ...activityToDuplicate };
    newActivity.id = null; // Set id to null to create a new activity
    navigation.navigate("Edit Library Workout", {
      libraryWorkout: newActivity,
      isDuplicateMode: true,
    });
  };

  const handleDelete = () => {
    closeMenu();
    setDeleteConfirmationVisible(true); // Show delete confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      await supabase
        .from("workout_library")
        .delete()
        .eq("id", route.params.item.id);
      setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
      setFetchWorkoutLibraryRequired(dispatch, true); // Fetch workout library
      navigation.goBack();
    } catch (error) {
      console.error("Error deleting library workout:", error);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
  };

  return (
    <>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={<Appbar.Action icon="dots-vertical" onPress={openMenu} />}
      >
        <Menu.Item onPress={handleEdit} title="Edit" leadingIcon={ICONS.EDIT} />
        <Menu.Item
          onPress={handleDuplicate}
          title="Duplicate"
          leadingIcon={ICONS.DUPLICATE}
        />
        <Menu.Item
          onPress={handleDelete}
          title="Delete"
          leadingIcon={ICONS.DELETE}
        />
      </Menu>
      <Portal>
        <Dialog visible={deleteConfirmationVisible} onDismiss={cancelDelete}>
          <Dialog.Title>Confirmation</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Are you sure you want to delete this library workout?
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancelDelete}>Cancel</Button>
            <Button onPress={confirmDelete}>Delete</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};

export default LibraryWorkoutAppBarMenu;
