import {
  UserProfile,
  CoachedAthleteProfile,
  CoachProfile,
} from "../../types/user";

export interface ProfileState {
  isLoadingProfile: boolean;
  isLoadingAthletes: boolean;
  isLoadingCoaches: boolean;
  fetchWorkoutLibraryRequired: boolean;
  profile: UserProfile | null;
  athletes: CoachedAthleteProfile[];
  coaches: CoachProfile[];
  coachView: CoachViewContext;
  error: any;
}

export enum ProfileActionTypes {
  SET_LOADING = "SET_LOADING",
  FETCH_PROFILE = "FETCH_SCHEDULE",
  FETCH_PROFILE_SUCCESS = "FETCH_SCHEDULE_SUCCESS",
  FETCH_PROFILE_FAILURE = "FETCH_SCHEDULE_FAILURE",
  FETCH_ATHLETES = "FETCH_ATHLETES",
  FETCH_ATHLETES_SUCCESS = "FETCH_ATHLETES_SUCCESS",
  FETCH_ATHLETES_FAILURE = "FETCH_ATHLETES_FAILURE",
  FETCH_COACHES = "FETCH_COACHES",
  FETCH_COACHES_SUCCESS = "FETCH_COACHES_SUCCESS",
  FETCH_COACHES_FAILURE = "FETCH_COACHES_FAILURE",
  SET_COACH_VIEW = "SET_COACH_VIEW",
  SET_FETCH_WORKOUT_LIBRARY_REQUIRED = "SET_FETCH_WORKOUT_LIBRARY_REQUIRED",
}

export type ProfileActionType = keyof typeof ProfileActionTypes;

export type SetLoadingAction = {
  type: ProfileActionTypes.SET_LOADING;
  isLoadingProfile: boolean;
};

export type FetchProfileAction = {
  type: ProfileActionTypes.FETCH_PROFILE;
};

export type FetchProfileSuccessAction = {
  type: ProfileActionTypes.FETCH_PROFILE_SUCCESS;
  profile: UserProfile;
};

export type FetchProfileFailureAction = {
  type: ProfileActionTypes.FETCH_PROFILE_FAILURE;
  error: any;
};

export type FetchAthletesAction = {
  type: ProfileActionTypes.FETCH_ATHLETES;
};

export type FetchAthletesSuccessAction = {
  type: ProfileActionTypes.FETCH_ATHLETES_SUCCESS;
  athletes: CoachedAthleteProfile[];
};

export type FetchAthletesFailureAction = {
  type: ProfileActionTypes.FETCH_ATHLETES_FAILURE;
  error: any;
};

export type FetchCoachesAction = {
  type: ProfileActionTypes.FETCH_COACHES;
};

export type FetchCoachesSuccessAction = {
  type: ProfileActionTypes.FETCH_COACHES_SUCCESS;
  coaches: CoachProfile[];
};

export type FetchCoachesFailureAction = {
  type: ProfileActionTypes.FETCH_COACHES_FAILURE;
  error: any;
};

export type SetCoachViewAction = {
  type: ProfileActionTypes.SET_COACH_VIEW;
  coachView: CoachViewContext;
};

export type SetFetchWorkoutLibraryRequiredAction = {
  type: ProfileActionTypes.SET_FETCH_WORKOUT_LIBRARY_REQUIRED;
  fetchWorkoutLibraryRequired: boolean;
};

export type ProfileAction =
  | SetLoadingAction
  | FetchProfileAction
  | FetchProfileSuccessAction
  | FetchProfileFailureAction
  | FetchAthletesAction
  | FetchAthletesSuccessAction
  | FetchAthletesFailureAction
  | FetchCoachesAction
  | FetchCoachesSuccessAction
  | FetchCoachesFailureAction
  | SetCoachViewAction
  | SetFetchWorkoutLibraryRequiredAction;

export type CoachViewContextType =
  | CoachViewContextTypes.ATHLETE
  | CoachViewContextTypes.SELF
  | CoachViewContextTypes.SUMMARY;

export enum CoachViewContextTypes {
  ATHLETE = "ATHLETE",
  SELF = "SELF",
  SUMMARY = "SUMMARY",
}

export enum AthleteViewContextTypes {
  SELF = "SELF",
}

export interface CoachedAthleteView {
  type: CoachViewContextTypes.ATHLETE;
  id: string;
}

interface SelfView {
  type: CoachViewContextTypes.SELF;
}

interface CoachesSummaryView {
  type: CoachViewContextTypes.SUMMARY;
}

export type CoachViewContext =
  | SelfView
  | CoachedAthleteView
  | CoachesSummaryView;
