import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Text, Divider } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { formatDuration, formatDate } from "../components/utils/time";
import ScreenWrapper from "../components/ScreenWrapper";
import EventCardTitle from "../components/Event/EventCardTitle";
import { getActivityStatusColor } from "../components/utils/activityStatusColor";
import { fetchEventById } from "../api/events";
import { EventData } from "../types/event";
import EventDetailsList from "../components/Event/EventDetailsList";
import { useSchedule } from "../provider/ScheduleProvider";
import Loading from "./utils/Loading";

type EventDetailsProps = NativeStackScreenProps<MainStackParamList>;

const EventDetails: React.FC<EventDetailsProps> = ({ route, navigation }) => {
  const { event } = route.params as { event: EventData };
  const [updatedEvent, setUpdatedEvent] = useState<EventData>(event);

  const { state, dispatch } = useSchedule();

  const eventStatusColor = getActivityStatusColor(
    updatedEvent.is_completed,
    updatedEvent.event_date
  );

  const formattedDuration = formatDuration(updatedEvent.total_time_planned);
  const formattedEventDate = formatDate(
    updatedEvent.event_date,
    updatedEvent.is_completed ?? false
  );

  // Fetch activity when the component mounts
  async function fetchUpdatedEvent(id: string) {
    try {
      const fetchedEvent = await fetchEventById(id);
      setUpdatedEvent(fetchedEvent as EventData);
      return fetchedEvent;
    } catch (error) {
      // Handle errors here
      console.error("Error fetching event:", error);
    } finally {
    }
  }

  useEffect(() => {
    if (!state.isLoading) {
      // TODO: replace this API fetch with a call to the global state
      fetchUpdatedEvent(updatedEvent.id);
    }
  }, [state.schedule]);

  if (state.isLoading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <EventCardTitle
          title={updatedEvent.title}
          activityTypeEnum={updatedEvent.type}
          isCompleted={updatedEvent.is_completed}
          eventDate={updatedEvent.event_date}
          titleVariant="titleLarge"
        />
        <Text
          style={[
            styles.startedAt,
            { color: eventStatusColor }, // Conditionally set text color
          ]}
        >
          {formattedEventDate}
        </Text>
        {updatedEvent.description && (
          <View>
            <Text variant="labelMedium" style={styles.descriptionTitle}>
              Description
            </Text>
            <Text style={styles.description}>{updatedEvent.description}</Text>
          </View>
        )}
      </View>
      <Divider style={styles.divider} />
      <EventDetailsList event={updatedEvent} />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginTop: 16,
  },
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  descriptionTitle: {
    // fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: 8,
    marginTop: 16,
  },
});

export default EventDetails;
