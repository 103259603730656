import { CoachedAthleteProfile } from "../../types/user";
import {
  CoachViewContextTypes,
  ProfileState,
  CoachedAthleteView,
} from "./types";

export const getCurrentViewUserId = (state: ProfileState) => {
  return state.coachView.type === CoachViewContextTypes.ATHLETE
    ? state.coachView.id
    : state.profile
    ? state.profile.id
    : null;
};

export const getCurrentViewUserProfile = (state: ProfileState) => {
  if (state.coachView.type === CoachViewContextTypes.ATHLETE) {
    return state.athletes.find(
      (athlete: CoachedAthleteProfile) =>
        athlete.id === (state.coachView as CoachedAthleteView).id
    );
  }
  return state.profile;
};
