import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Agenda, DateData } from "react-native-calendars";
import AgendaItem from "../components/Activity/AgendaItem";
import Loading from "./utils/Loading";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { MainStackParamList } from "../types/navigation";
import { getCalendarTheme } from "../provider/ThemeProvider/calendarTheme";
import ScheduleFabGroup from "../components/ScheduleFabGroup";
import { formatDateWithClientTimeZone } from "../components/utils/dateUtils";
import { useSchedule } from "../provider/ScheduleProvider";
import { ScheduleActionTypes } from "../provider/ScheduleProvider/types";
import { getOldestAndNewestDates } from "../provider/ScheduleProvider/selectors";
import { extendSchedule } from "../provider/ScheduleProvider/actions";
import { getCurrentViewUserId } from "../provider/ProfileProvider/selectors";
import { useProfile } from "../provider/ProfileProvider";

interface Props extends NativeStackScreenProps<MainStackParamList, "MainTabs"> {
  weekView?: boolean;
}

const Schedule = (props: Props) => {
  const { navigation } = props;
  const { state, dispatch } = useSchedule();
  const { state: profileState } = useProfile();
  const theme = getCalendarTheme();

  const doExtendSchedule = async (
    oldestDateFetched: Date,
    newestDateFetched: Date
  ) => {
    try {
      const user_id = getCurrentViewUserId(profileState);
      if (!user_id) {
        throw new Error("No user_id found");
      }

      if (new Date(state.focusDate) < oldestDateFetched) {
        await extendSchedule(
          dispatch,
          user_id,
          new Date(
            new Date(state.focusDate).setDate(
              new Date(state.focusDate).getDate() - 7
            )
          ), // subtract 7 days to focusDate
          oldestDateFetched
        );
      }

      if (new Date(state.focusDate) > newestDateFetched) {
        await extendSchedule(
          dispatch,
          user_id,
          newestDateFetched,
          new Date(
            new Date(state.focusDate).setDate(
              new Date(state.focusDate).getDate() + 7
            )
          ) // add 7 days to focusDate
        );
      }
    } catch (error) {
      // Handle errors here
      console.error("Error extending schedule:", error);
    }
  };

  // hook to extend global schedule when the selectedChartRangeStart or selectedChartRangeEnd changes
  useEffect(() => {
    const { oldestDate: oldestDateFetched, newestDate: newestDateFetched } =
      getOldestAndNewestDates(state.schedule);
    if (
      new Date(state.focusDate) < oldestDateFetched ||
      new Date(state.focusDate) > newestDateFetched
    ) {
      doExtendSchedule(oldestDateFetched, newestDateFetched);
    }
  }, [state.focusDate]);

  const styles = StyleSheet.create({
    calendar: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    header: {
      backgroundColor: theme.backgroundColor,
    },
    section: {
      backgroundColor: theme.backgroundColor,
      color: theme.dayTextColor,
    },
  });

  const renderItem = (item: any) => {
    return <AgendaItem item={item} navigation={navigation} />;
  };

  const onDateChanged = (date: DateData) => {
    dispatch({
      type: ScheduleActionTypes.SET_FOCUS_DATE,
      focusDate: date.dateString,
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <Agenda
        selected={formatDateWithClientTimeZone(new Date().toISOString())}
        items={state.schedule}
        theme={theme}
        style={styles.section}
        showClosingKnob={true}
        firstDay={1}
        // Callback that gets called when items for a certain month should be loaded (month became visible)
        // loadItemsForMonth={(month) => {console.log('trigger items loading', month)}}
        // Callback that fires when the calendar is opened or closed
        // onCalendarToggled={}
        // Callback that gets called on day press
        onDayPress={onDateChanged}
        // Callback that gets called when day changes while scrolling agenda list
        onDayChange={onDateChanged}
        renderItem={renderItem}
        renderEmptyData={() => <Loading />}
        // Specify how each item should be rendered in agenda
        // renderDay={(day, item) => renderDay(day)}
      />
      <ScheduleFabGroup navigation={navigation} />
    </View>
  );
};

export default Schedule;
