import React, { useState } from "react";
import {
  Menu,
  Portal,
  Dialog,
  Paragraph,
  Button,
  Appbar,
} from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ICONS } from "../../constants/icons";
import { supabase } from "../../initSupabase";
import { updateScheduleForDates } from "../../provider/ScheduleProvider/actions";
import { getLocalStartOfDayForDate } from "../utils/dateUtils";
import { useSchedule } from "../../provider/ScheduleProvider";
import { useProfile } from "../../provider/ProfileProvider";
import { getCurrentViewUserId } from "../../provider/ProfileProvider/selectors";

const ActivityAppBarMenu = () => {
  const { dispatch } = useSchedule();
  const { state: profileState } = useProfile();

  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const navigation = useNavigation();
  const route = useRoute();

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const handleEdit = () => {
    closeMenu();
    navigation.navigate("Edit Activity", {
      activity: route.params.activity,
    });
  };

  const handleDuplicate = () => {
    closeMenu();
    const activityToDuplicate = route.params.activity;
    const newActivity = { ...activityToDuplicate };
    newActivity.id = null; // Set id to null to create a new activity
    navigation.navigate("Edit Activity", {
      activity: newActivity,
      isDuplicateMode: true,
    });
  };

  const handleDelete = () => {
    closeMenu();
    setDeleteConfirmationVisible(true); // Show delete confirmation dialog
  };

  const handleMerge = () => {
    closeMenu();
    navigation.navigate("Merge Workouts", {
      activity: route.params.activity,
    });
  };

  const handleAddToLibrary = () => {
    closeMenu();
    console.log("add to workout library");
    const activityToDuplicate = route.params.activity;
    const newActivity = { ...activityToDuplicate };
    newActivity.id = null; // Set id to null to create a new activity
    navigation.navigate("Edit Library Workout", {
      libraryWorkout: newActivity,
      isDuplicateMode: true,
    });
  };

  const confirmDelete = async () => {
    try {
      await supabase
        .from("activities")
        .delete()
        .eq("id", route.params.activity.id);
      setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
      // set state to fetch schedule for this workout date after deleting
      updateScheduleForDates(
        dispatch,
        getCurrentViewUserId(profileState),
        getLocalStartOfDayForDate(new Date(route.params.activity.workout_date))
      );
      navigation.goBack();
    } catch (error) {
      console.error("Error deleting activity:", error);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
  };

  return (
    <>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={<Appbar.Action icon="dots-vertical" onPress={openMenu} />}
      >
        <Menu.Item onPress={handleEdit} title="Edit" leadingIcon={ICONS.EDIT} />
        <Menu.Item
          onPress={handleDuplicate}
          title="Duplicate"
          leadingIcon={ICONS.DUPLICATE}
        />
        <Menu.Item
          onPress={handleMerge}
          title="Merge/Unmerge"
          leadingIcon={ICONS.MERGE}
        />
        <Menu.Item
          onPress={handleAddToLibrary}
          title="Add to Workout Library"
          leadingIcon={ICONS.WORKOUT_LIBRARY}
        />
        <Menu.Item
          onPress={handleDelete}
          title="Delete"
          leadingIcon={ICONS.DELETE}
        />
      </Menu>
      <Portal>
        <Dialog visible={deleteConfirmationVisible} onDismiss={cancelDelete}>
          <Dialog.Title>Confirmation</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Are you sure you want to delete this activity?
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancelDelete}>Cancel</Button>
            <Button onPress={confirmDelete}>Delete</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};

export default ActivityAppBarMenu;
