import * as DocumentPicker from "expo-document-picker";
import { MAX_FILE_SIZE } from "../../constants/fileio";
import * as FileSystem from "expo-file-system";
import { Linking, Platform } from "react-native";
import showAlert from "./showAlert";
import { downloadFromStorage } from "../../api/storage";
import { FileObject } from "@supabase/storage-js";
import { supabase } from "../../initSupabase";

// Function to open the native device picker
export const pickFileOrPhoto = async () => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*", // Allow all file types
      copyToCacheDirectory: true, // Ensure file is cached for consistent URI access
      multiple: false, // Set true if you want to allow selecting multiple files
    });

    if (result.type === "success") {
      // File successfully picked
      return result;
      // {
      //   uri: result.uri, // File URI
      //   name: result.name, // File name
      //   type: result.mimeType || "application/octet-stream", // MIME type (fallback to generic)
      // };
    } else {
      // User canceled the picker
      return null;
    }
  } catch (error) {
    console.error("Error picking file or photo:", error);
    throw error;
  }
};

// Function to return the human readable file size
export const formatFileSize = (size: number | undefined) => {
  if (!size) return "0 B";
  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};

// Function to check file size and return error if too large
export const checkFileSize = (file: DocumentPicker.DocumentResult) => {
  if (file.type !== "success") {
    return { success: false, message: "File size is missing." };
  }
  if (file.size && file.size > MAX_FILE_SIZE) {
    return {
      success: false,
      message: `File size exceeds the limit of ${
        MAX_FILE_SIZE / (1024 * 1024)
      } MB.`,
    };
  }
  return { success: true };
};

// export const openDownloadedFile = async (data, fileName: string) => {
//   try {
//     const fileUri = `${FileSystem.documentDirectory}${fileName}`;
//     await FileSystem.writeAsStringAsync(fileUri, await data.text(), {
//       encoding: FileSystem.EncodingType.Base64,
//     });
//     console.log("File downloaded to:", fileUri);
//     await Linking.openURL(fileUri);
//   } catch (error) {
//     console.error("Error downloading or opening file:", error);
//     showAlert("Error", "Failed to download or open file");
//   }
// };

export const openDownloadedFile = async (data: Blob, fileName: string) => {
  try {
    if (Platform.OS === "web") {
      // Create a blob from the data
      const url = window.URL.createObjectURL(data);

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // For native platforms
      const fileUri = `${FileSystem.documentDirectory}${fileName}`;
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64data = reader.result as string;
        await FileSystem.writeAsStringAsync(fileUri, base64data, {
          encoding: FileSystem.EncodingType.Base64,
        });
        console.log("File downloaded to:", fileUri);
        await Linking.openURL(fileUri);
      };

      reader.readAsDataURL(data);
    }
  } catch (error) {
    console.error("Error downloading or opening file:", error);
    showAlert("Error", "Failed to download or open file");
  }
};

export const checkIsImage = (file: FileObject) => {
  return file.metadata.mimetype.startsWith("image/");
};

export const checkIsPDF = (file: FileObject) => {
  return file.metadata.mimetype === "application/pdf";
};
