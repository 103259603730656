import {
  emptyLibraryWorkoutData,
  LibraryWorkoutData,
} from "../../types/libraryWorkout";
import { ActivityData } from "../../types/activity";
import { getLocalStartOfDayForDate } from "../utils/dateUtils";
import { ActivityIndicatorBase } from "react-native";
import { id } from "date-fns/locale";
import { tr } from "react-native-paper-dates";

// function to take a workout/activity-like object, delete non-library workout fields, and return a LibraryWorkoutData object
export const convertToLibraryWorkoutData = (
  workout: any,
  isEditMode: boolean = false
): LibraryWorkoutData => {
  const libraryWorkoutData: LibraryWorkoutData = {
    type: workout.type ?? null,
    title: workout.title ?? null,
    description: workout.description ?? null,
    data: workout.data ?? null,
    total_distance_planned: workout.total_distance_planned ?? null,
    total_time_planned: workout.total_time_planned ?? null,
    average_speed_planned: workout.average_speed_planned ?? null,
    workout_library_category_id: workout.workout_library_category_id ?? null,
  };
  if (isEditMode) {
    libraryWorkoutData.id = workout.id ?? null;
  }
  return libraryWorkoutData;
};

// function to take a LibraryWorkoutData object, delete non-library workout fields, and return a workout/activity-like object
// LibraryWorkoutData {
//   id: string
//   average_speed_planned: number | null
//   created_at: string
//   data: Json | null
//   deleted_at: string | null
//   description: string | null
//   title: string | null
//   total_distance_planned: number | null
//   total_time_planned: number | null
//   type: number | null
//   updated_at: string | null
//   user_id: string | null
//   workout_library_category_id: string | null
// }

// ActivityData {
//   id: string | null
//   average_speed: number | null
//   average_speed_planned: number | null
//   created_at: string | null
//   data: Json | null
//   deleted_at: string | null
//   description: string | null
//   is_completed: boolean | null
//   is_planned: boolean
//   started_at: string | null
//   started_at_local: string | null
//   title: string | null
//   total_distance: number | null
//   total_distance_planned: number | null
//   total_time: number | null
//   total_time_planned: number | null
//   type: number | null
//   updated_at: string | null
//   user_id: string | null
//   utc_offset: number | null
//   workout_date: string | null
// }
export const convertLibraryWorkoutToActivity = (
  item: LibraryWorkoutData,
  workoutDate: Date,
  user_id: string
): ActivityData => {
  const utcOffset = new Date().getTimezoneOffset() * -60;
  const workoutDateStr = getLocalStartOfDayForDate(workoutDate);
  const startedAtUtc = new Date(workoutDate);
  startedAtUtc.setSeconds(startedAtUtc.getSeconds() - utcOffset);

  const activity: ActivityData = {
    average_speed: null,
    average_speed_planned: item.average_speed_planned ?? null,
    data: item.data ?? null,
    description: item.description ?? null,
    is_completed: false,
    is_planned: true,
    started_at: workoutDate.toISOString(),
    started_at_local: workoutDateStr,
    title: item.title ?? null,
    total_distance: null,
    total_distance_planned: item.total_distance_planned ?? null,
    total_time: null,
    total_time_planned: item.total_time_planned ?? null,
    type: item.type,
    user_id,
    utc_offset: utcOffset,
    workout_date: workoutDateStr,
  };

  return activity;
};
