import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { List } from "react-native-paper";
import { EventData } from "../../types/event";
import EventAttachmentsSection from "./EventAttachmentsGroup";

type EventDetailsListProps = {
  event: EventData;
};

const EventDetailsList: React.FC<EventDetailsListProps> = ({ event }) => {
  return (
    <View>
      <List.AccordionGroup>
        <List.Accordion title="Goals" id="1">
          <List.Item title="Add a goal" />
        </List.Accordion>
        <EventAttachmentsSection eventId={event.id} userId={event.user_id} />
        <List.Accordion title="Checklists" id="3">
          <List.Item title="Create a checklist" />
        </List.Accordion>
        <List.Accordion title="Analysis" id="4">
          <List.Item title="Charts" />
          <List.Item title="Time in Zones" />
          <List.Item title="Best Efforts" />
        </List.Accordion>
        <List.Accordion title="Splits" id="5">
          <List.Item title="Splits" />
        </List.Accordion>
        <List.Accordion title="Map" id="6">
          <List.Item title="Item 2" />
        </List.Accordion>
        <List.Accordion title="Notes" id="7">
          <List.Item title="Notes" />
        </List.Accordion>
      </List.AccordionGroup>
    </View>
  );
};

const styles = StyleSheet.create({
  badgeContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

export default EventDetailsList;
