import React from "react";
import { Alert, Platform } from "react-native";

interface DeleteConfirmationProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  message,
  onConfirm,
  onCancel,
}) => {
  const showAlert = () => {
    if (Platform.OS === "web") {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    } else {
      Alert.alert(
        "Confirm Delete",
        message,
        [
          {
            text: "Cancel",
            style: "cancel",
            onPress: onCancel,
          },
          {
            text: "Delete",
            style: "destructive",
            onPress: onConfirm,
          },
        ],
        { cancelable: true }
      );
    }
  };

  React.useEffect(() => {
    showAlert();
  }, []);

  return null;
};

export default DeleteConfirmation;
