import React, { useEffect, useState } from "react";
import { Card, Text } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import TotalsDataTable from "../TotalsDataTable";
import { WeeklyActivityTotalsPerActivityCollection } from "../../types/activity";
import BarChart from "../charts/LongTermTrainingSummaryBarChart";
import { convertDailyTotalsObjectToArray } from "../utils/activityUtils";
import TotalTypeSelect from "../TotalTypeSelect";
import {
  formatDateWithClientTimeZone,
  getEndOfWeekForDate,
  getStartOfNextWeekForDate,
  getStartOfWeekForDate,
} from "../utils/dateUtils";
import { TotalType } from "../../types/activity";
import Loading from "../../screens/utils/Loading";
import DateRangeSelector from "./DateRangeSelector";
import WeekDisplay from "./WeekDisplay";
import ActivityTypeToggle from "./ActivityTypeToggle";
import { useSchedule } from "../../provider/ScheduleProvider";
import {
  calculateWorkoutWeeklyTotalsPerActivityTypeForDateRange,
  getOldestAndNewestDates,
} from "../../provider/ScheduleProvider/selectors";
import { useProfile } from "../../provider/ProfileProvider";
import { getCurrentViewUserId } from "../../provider/ProfileProvider/selectors";
import { extendSchedule } from "../../provider/ScheduleProvider/actions";

const LongTermTrainingSummaryCard = () => {
  const { state: profileState } = useProfile();
  const { state: scheduleState, dispatch: scheduleDispatch } = useSchedule();
  const [selectedKey, setSelectedKey] = useState<string>("total");
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [selectedTotalType, setSelectedTotalType] = useState<TotalType>(
    TotalType.DURATION
  );
  const [selectedWeekStart, setSelectedWeekStart] = useState(
    getStartOfWeekForDate(new Date()) // Initialize with the start of the current week
  );
  // get end of next week and start of week 17 weeks prior
  const initChartRange = 17;
  const endOfNextWeek: Date = getEndOfWeekForDate(
    getStartOfNextWeekForDate(new Date())
  );
  const startOfWeek17WeeksAgo: Date = getStartOfWeekForDate(
    new Date(
      endOfNextWeek.getTime() - (initChartRange - 1) * 7 * 24 * 60 * 60 * 1000 //subtract 1 week to get the start of the block not the week earlier
    )
  );

  const [selectedChartRangeEnd, setSelectedChartRangeEnd] =
    useState<Date>(endOfNextWeek);
  const [selectedChartRangeStart, setSelectedChartRangeStart] = useState<Date>(
    startOfWeek17WeeksAgo
  );
  const [chartRangeWeeks, setChartRangeWeeks] =
    useState<number>(initChartRange);
  const [weeklyTotals, setWeeklyTotals] =
    useState<WeeklyActivityTotalsPerActivityCollection | null>();

  const handleToggle = (value: string) => {
    // The ToggleButton.Row value appeasr to be null if the selected button is selected again?
    // To fix this, only update selectedKey if value is not null
    if (value !== null) {
      setSelectedKey(value);
    }
  };

  // Callback function to handle the selected value
  const handleTotalTypeSelect = (value: TotalType) => {
    setSelectedTotalType(value);
  };

  const doExtendSchedule = async (
    oldestDateFetched: Date,
    newestDateFetched: Date
  ) => {
    try {
      setChartLoading(true);
      const user_id = getCurrentViewUserId(profileState);
      if (!user_id) {
        throw new Error("No user_id found");
      }

      if (oldestDateFetched && selectedChartRangeStart < oldestDateFetched) {
        await extendSchedule(
          scheduleDispatch,
          user_id,
          selectedChartRangeStart,
          oldestDateFetched
        );
      }

      if (newestDateFetched && selectedChartRangeEnd > newestDateFetched) {
        await extendSchedule(
          scheduleDispatch,
          user_id,
          newestDateFetched,
          selectedChartRangeEnd
        );
      }
    } catch (error) {
      // Handle errors here
      console.error("Error extending schedule", error);
    } finally {
      setChartLoading(false);
    }
  };

  const calculateTotals = async () => {
    try {
      setChartLoading(true);
      const totals = calculateWorkoutWeeklyTotalsPerActivityTypeForDateRange(
        scheduleState.schedule,
        selectedChartRangeStart,
        selectedChartRangeEnd
      );
      setWeeklyTotals(totals);
    } catch (error) {
      console.error("Error fetching updating totals:", error);
    } finally {
      setChartLoading(false);
    }
  };

  // hook to extend global schedule when the selectedChartRangeStart or selectedChartRangeEnd changes
  useEffect(() => {
    const { oldestDate: oldestDateFetched, newestDate: newestDateFetched } =
      getOldestAndNewestDates(scheduleState.schedule);
    if (
      (oldestDateFetched && selectedChartRangeStart < oldestDateFetched) ||
      (newestDateFetched && selectedChartRangeEnd > newestDateFetched)
    ) {
      doExtendSchedule(oldestDateFetched, newestDateFetched);
    }
    calculateTotals();
  }, [selectedChartRangeStart, selectedChartRangeEnd]);

  // hook to recalculate totals when the scheduleState.schedule changes
  useEffect(() => {
    calculateTotals();
  }, [scheduleState.schedule]);

  const handleBarClick = (data: { date?: string }) => {
    if (data.date) {
      setSelectedWeekStart(new Date(data.date));
    }
  };

  const handleChartRangeChange = (rangeStart: Date, rangeEnd: Date) => {
    setSelectedChartRangeStart(rangeStart);
    setSelectedChartRangeEnd(rangeEnd);
    handleBarClick({ date: formatDateWithClientTimeZone(rangeStart) });
  };

  return (
    <Card style={styles.container}>
      <Card.Title title="Long Term Training Summary" />
      <DateRangeSelector
        dateRangeStart={selectedChartRangeStart}
        dateRangeEnd={selectedChartRangeEnd}
        setDateRange={handleChartRangeChange}
        rangeInWeeks={chartRangeWeeks}
      />

      <Card.Content>
        {chartLoading || scheduleState.isLoading ? (
          <Loading />
        ) : weeklyTotals && weeklyTotals[selectedKey] ? (
          <View>
            <ActivityTypeToggle
              activityTotals={weeklyTotals}
              selectedType={selectedKey}
              onTypeChange={handleToggle}
            />

            <Text variant="titleMedium" style={styles.summaryTitle}>
              {selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)}
            </Text>
            <TotalTypeSelect onSelect={handleTotalTypeSelect} />
            <BarChart
              data={convertDailyTotalsObjectToArray(
                weeklyTotals[selectedKey]["weekly"]
              )}
              labelKey="date"
              totalType={selectedTotalType}
              onBarClick={handleBarClick}
            />
            <View style={styles.tableTitle}>
              <WeekDisplay selectedWeekStart={selectedWeekStart} />
            </View>
            <TotalsDataTable
              totals={
                weeklyTotals[selectedKey]["weekly"][
                  formatDateWithClientTimeZone(selectedWeekStart)
                ]
              }
            />
          </View>
        ) : (
          <View style={styles.summaryTitle}>
            <Text>
              No data. Plan some training sessions in the Schedule tab and
              connect Strava for tracking completed activities...
            </Text>
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  tableTitle: {
    // flex: 1,
    paddingTop: 16,
  },
  summaryTitle: {
    marginTop: 8,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    marginHorizontal: 8, // Adjust the spacing as needed
  },
  toggleButtonRow: {
    // justifyContent: "center",
    // marginTop: 10,
  },

  toggleButton: {
    // width: 100,
  },

  selectedToggleButton: {
    // width: 100,
    // backgroundColor: "blue", // Customize the selected button's background color
  },
});

export default LongTermTrainingSummaryCard;
