import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useNavigation } from "@react-navigation/native";
import { fetchProfile, updateProfile } from "../api/users";
import { supabase } from "../initSupabase";
import { Database } from "../types/supabase";
import { DatePickerInput } from "react-native-paper-dates";
import { UserProfile, initialUserProfile } from "../types/user";
import SuperButton from "../components/AdminTools/SuperButton";
import {
  createStravaWebhookEventsSubscription,
  deleteStravaWebhookEventsSubscription,
  simulateStravaCallbackValidation,
  viewStravaWebhookEventsSubscription,
} from "../api/adminTools";
import ErrorMessageText from "../components/AdminTools/ErrorMessage";
import { SupabaseFunctionsResponse } from "../types/api";

function AdminTools() {
  const navigation = useNavigation();
  const [apiResponse, setApiResponse] =
    useState<SupabaseFunctionsResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("No error");

  function handleMenuItemPress(arg0: string): void {
    throw new Error("Function not implemented.");
  }

  const fetchApiResponse = async () => {
    // Simulate fetching data
    return "Data from API";
  };

  return (
    <ScreenWrapper withForms={true}>
      <List.Section>
        <List.Subheader>Strava Webhook Events API</List.Subheader>
        <View style={styles.buttonContainer}>
          <SuperButton
            apiFunction={createStravaWebhookEventsSubscription}
            buttonText="Create Subscription"
            onDataReceived={setApiResponse}
          />
        </View>
        <View style={styles.buttonContainer}>
          <SuperButton
            apiFunction={simulateStravaCallbackValidation}
            buttonText="Simulate Validation"
            onDataReceived={setApiResponse}
          />
        </View>
        <View style={styles.buttonContainer}>
          <SuperButton
            apiFunction={viewStravaWebhookEventsSubscription}
            buttonText="View Subscription"
            onDataReceived={setApiResponse}
          />
        </View>
        <View style={styles.buttonContainer}>
          <SuperButton
            apiFunction={deleteStravaWebhookEventsSubscription}
            buttonText="Delete Subscription"
            onDataReceived={setApiResponse}
          />
        </View>
        {apiResponse && (
          <View>
            <Text variant="labelSmall">Data</Text>
            <Text>{JSON.stringify(apiResponse.data, null, 2)}</Text>
            <Text variant="labelSmall">Error</Text>
            <ErrorMessageText apiResponse={apiResponse} />
          </View>
        )}
      </List.Section>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    // alignItems: "center",
    marginBottom: 16,
  },
  saveButton: {
    width: 150,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});

export default AdminTools;
