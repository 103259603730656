import { ScheduleAction, ScheduleActionTypes, ScheduleState } from "./types";

export default function scheduleReducer(
  state: ScheduleState,
  action: ScheduleAction
): ScheduleState {
  switch (action.type) {
    case ScheduleActionTypes.SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case ScheduleActionTypes.FETCH_SCHEDULE:
      return { ...state, isLoading: true };
    case ScheduleActionTypes.FETCH_SCHEDULE_SUCCESS:
      return { ...state, isLoading: false, schedule: action.schedule };
    case ScheduleActionTypes.FETCH_SCHEDULE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case ScheduleActionTypes.EXTEND_SCHEDULE:
      return { ...state, isLoading: true };
    case ScheduleActionTypes.EXTEND_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schedule: { ...state.schedule, ...action.schedule },
      };
    case ScheduleActionTypes.EXTEND_SCHEDULE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case ScheduleActionTypes.UPDATE_SCHEDULE:
      return { ...state, isLoading: true };
    case ScheduleActionTypes.UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schedule: { ...state.schedule, ...action.schedule },
      };
    case ScheduleActionTypes.UPDATE_SCHEDULE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case ScheduleActionTypes.SET_FOCUS_DATE:
      return { ...state, focusDate: action.focusDate };
    default:
      return state;
  }
}
