import { subDays, addDays } from "date-fns";
import { Schedule } from "../types/schedule";
import { supabase } from "../initSupabase";

export async function fetchScheduleInDateRange(
  user_id?: string | null,
  startDate: Date | string = subDays(new Date(), 90),
  endDate: Date | string = addDays(new Date(), 90),
  includeNextEvent: boolean = false // search past the end date for the next event
  //   pageSize: number = 20
) {
  try {
    const { data, error } = await supabase.functions.invoke("schedule", {
      body: {
        user_id: user_id,
        start_date: startDate,
        end_date: endDate,
        include_next_event: includeNextEvent,
      },
    });

    if (error) {
      throw error;
    }

    // TODO: paginate the results (an object is retunred not an array so need to figure out best way to do this)

    // return number of items in the schedule
    // data = { '2024-12-30': [{item1}, {item2}], '2024-12-31': [{item1}, {item2}] }
    return { data, count: calculateScheduleCount(data), error };
  } catch (error) {
    console.error("Error fetching activities in range:", error);
    throw error;
  }
}

// Assuming `data` is of type `Schedule`
// data = { '2024-12-30': [{item1}, {item2}], '2024-12-31': [{item1}, {item2}] }
// returns 4
export const calculateScheduleCount = (data: Schedule) => {
  // Calculate the number of items in the schedule
  const count = Object.values(data).reduce(
    (acc, items) => acc + items.length,
    0
  );

  return count;
};
