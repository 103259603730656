import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { List, useTheme } from "react-native-paper";
import { Text, TextInput, Button, Divider } from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import Switch from "../components/input/Switch";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import CustomPicker from "../components/input/CustomPicker";
import DurationInput from "../components/input/DurationInput";
import PaceInput from "../components/input/PaceInput";
import { supabase } from "../initSupabase";
import { types } from "../constants/activityTypes";
import DistanceInput from "../components/input/DistanceInput";
import {
  userUnitsToMeters,
  metersToUserUnits,
} from "../components/utils/distance";
import {
  LibraryWorkoutData,
  emptyLibraryWorkoutData,
} from "../types/libraryWorkout";
import {
  createLibraryWorkout,
  updateLibraryWorkout,
  fetchWorkoutLibraryCategories,
} from "../api/workouts";
import { convertToLibraryWorkoutData } from "../components/utils/workoutLibraryUtils";
import { useProfile } from "../provider/ProfileProvider";
import { setFetchWorkoutLibraryRequired } from "../provider/ProfileProvider/actions";

type EditActivityProps = NativeStackScreenProps<
  MainStackParamList,
  "Edit Library Workout"
> & { isDuplicateMode?: boolean }; // Add isDuplicateMode props

const EditLibraryWorkout: React.FC<EditActivityProps> = ({
  navigation,
  route,
}) => {
  // this screen handles create, update library workouts
  // get library workout from route params
  const { libraryWorkout: libraryWorkoutFromRouteParams, isDuplicateMode } =
    route.params;
  const { dispatch } = useProfile();
  const isEditMode = !!libraryWorkoutFromRouteParams.id;
  const libraryWorkout = convertToLibraryWorkoutData(
    libraryWorkoutFromRouteParams,
    isEditMode
  );
  // initialise local state
  const [title, setTitle] = useState(
    isEditMode || isDuplicateMode
      ? libraryWorkout.title || "Workout"
      : "Workout"
  );
  const [description, setDescription] = useState<string | null>(
    isEditMode || isDuplicateMode ? libraryWorkout.description || "" : ""
  );
  const [selectedType, setSelectedType] = useState<number | null>(
    isEditMode || isDuplicateMode ? libraryWorkout.type || 1 : 1
  );
  const emptyCategory = { id: null, value: null, label: "None" };
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    isEditMode || isDuplicateMode
      ? libraryWorkout.workout_library_category_id || null
      : null
  );
  const [totalTimePlanned, setTotalTimePlanned] = useState<number | null>(
    isEditMode || isDuplicateMode
      ? libraryWorkout.total_time_planned || 0
      : 3600
  );
  const [totalDistancePlanned, setTotalDistancePlanned] = useState<
    number | null
  >(
    isEditMode || isDuplicateMode
      ? metersToUserUnits(libraryWorkout.total_distance_planned ?? null)
      : null
  );
  const [avgPacePlanned, setAvgPacePlanned] = useState<number | null>(null);
  const [error, setError] = useState<string>("");

  const [libraryWorkoutData, setLibraryWorkoutData] =
    useState<LibraryWorkoutData>(
      libraryWorkout ? libraryWorkout : emptyLibraryWorkoutData
    );
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    // fetch categories when screen mounts
    const fetchCategories = async () => {
      try {
        const { data } = await fetchWorkoutLibraryCategories();
        console.log("Categories:", data);
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Save library workout
  const handleSaveLibraryWorkout = async () => {
    if (!selectedType) {
      setError("Please select a type");
      return;
    }

    try {
      const libraryWorkout = {
        ...libraryWorkoutData,
        title,
        description,
        type: selectedType,
        total_time_planned: totalTimePlanned,
        total_distance_planned: userUnitsToMeters(totalDistancePlanned),
        average_speed_planned: avgPacePlanned,
        workout_library_category_id: selectedCategoryId,
      };

      // if is duplicate, remove id
      if (isDuplicateMode) {
        delete libraryWorkout.id;
      }

      if (isEditMode && libraryWorkoutData.id) {
        // Update existing activity
        await updateLibraryWorkout(libraryWorkout);
      } else {
        // Create new activity
        await createLibraryWorkout(libraryWorkout);
      }
      alert(
        isDuplicateMode
          ? "Workout duplicated successfully!"
          : isEditMode
          ? "Workout updated successfully!"
          : "Workout saved successfully!"
      );
      // set state to fetch activities again
      // TODO: signal to fetch only the updated workout library
      setFetchWorkoutLibraryRequired(dispatch, true);
      navigation.goBack();
    } catch (error) {
      console.error("Error saving workout:", error);
    }
  };

  // user input event handlers
  const handleDurationChange = (totalSeconds: number | null) => {
    setTotalTimePlanned(totalSeconds);
  };

  const handleDistanceChange = (totalDistance: number | null) => {
    setTotalDistancePlanned(totalDistance);
  };

  const handlePaceChange = (totalSeconds: number) => {
    setAvgPacePlanned(totalSeconds);
  };

  // Transform categories to match the expected structure for CustomPicker
  const transformedCategories = [
    emptyCategory,
    ...categories.map((category) => ({
      ...category,
      value: category.id,
      label: category.title,
    })),
  ];

  return (
    <ScreenWrapper withForms={true}>
      <TextInput
        label="Title"
        value={title}
        onChangeText={(text) => setTitle(text)}
        style={styles.input}
      />
      <CustomPicker
        title="Type"
        options={types}
        selectedValue={selectedType}
        onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
      />
      <CustomPicker
        title="Category"
        options={transformedCategories}
        selectedValue={selectedCategoryId}
        onValueChange={setSelectedCategoryId}
      />
      <TextInput
        label="Description"
        value={description}
        onChangeText={(text) => setDescription(text)}
        multiline={true}
        numberOfLines={6}
        style={{ minHeight: 100 }}
      />
      <Divider />
      <List.Section>
        <DurationInput
          value={totalTimePlanned}
          onDurationChange={(totalSeconds) =>
            handleDurationChange(totalSeconds)
          }
        />
        <DistanceInput
          value={totalDistancePlanned}
          onDistanceChange={(value) => handleDistanceChange(value)}
        />
        <PaceInput
          defaultMinutes=""
          defaultSeconds=""
          onPaceChange={(totalSeconds: number) =>
            handlePaceChange(totalSeconds)
          }
        />
      </List.Section>
      <View style={styles.row}>
        <Button mode="contained" onPress={handleSaveLibraryWorkout}>
          {isDuplicateMode
            ? "Duplicate Workout"
            : isEditMode
            ? "Update Workout"
            : "Add Workout"}
        </Button>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
    // marginBottom: 12,
  },

  subheading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },

  row: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
});

export default EditLibraryWorkout;
