import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ScreenWrapper from "../components/ScreenWrapper";
import LongTermTrainingSummaryCard from "../components/Insights/LongTermTrainingSummaryCard";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  return (
    <ScreenWrapper>
      <LongTermTrainingSummaryCard />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
