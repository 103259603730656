import React from "react";
import { List } from "react-native-paper";
import { isStravaConnected } from "../utils/profileService";
import { useProfile } from "../../provider/ProfileProvider";

const ConnectedIcon: React.FC = () => {
  const { state } = useProfile();
  const isConnected = state.profile ? isStravaConnected(state.profile) : false;

  return <List.Icon icon={isConnected ? "cloud-check" : "cloud-off-outline"} />;
};

export default ConnectedIcon;
