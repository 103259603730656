import React, { useState } from "react";
import { View, StyleSheet, Alert } from "react-native";
import { Button } from "react-native-paper";
import { LibraryWorkoutData } from "../../types/libraryWorkout";
import { getCurrentViewUserId } from "../../provider/ProfileProvider/selectors";
import { getLocalStartOfDayForDate } from "../utils/dateUtils";
import { createActivity } from "../../api/activities";
import { useSchedule } from "../../provider/ScheduleProvider";
import { useProfile } from "../../provider/ProfileProvider";
import { updateScheduleForDates } from "../../provider/ScheduleProvider/actions";
import { ActivityData } from "../../types/activity";
import { convertLibraryWorkoutToActivity } from "../utils/workoutLibraryUtils";

interface ScheduleWorkoutButtonProps {
  item: LibraryWorkoutData;
  workoutDates: Date[] | Date; // can be a single date or an array of dates
  hideDialog: () => void; // Callback to hide the dialog
}

const ScheduleWorkoutButton = ({
  item,
  workoutDates,
  hideDialog,
}: ScheduleWorkoutButtonProps) => {
  const { state, dispatch } = useSchedule();
  const { state: profileState } = useProfile();

  const handleSchedule = async () => {
    try {
      // handle single date or array of dates
      if (!Array.isArray(workoutDates)) {
        workoutDates = [workoutDates];
      }
      const user_id = getCurrentViewUserId(profileState);
      // if user_id null then throw error
      if (!user_id) {
        throw new Error("User id is not available");
      }
      const utcOffset = new Date().getTimezoneOffset() * -60;
      // loop through workout dates and schedule workout
      for (const workoutDate of workoutDates) {
        const workoutDateStr = getLocalStartOfDayForDate(workoutDate);
        const startedAtUtc = new Date(workoutDate);
        startedAtUtc.setSeconds(startedAtUtc.getSeconds() - utcOffset);

        const activity: ActivityData = convertLibraryWorkoutToActivity(
          item,
          workoutDate,
          user_id
        );

        // Create new activity
        const { data, error } = await createActivity(activity);

        if (error) {
          throw error;
        }
      }
      Alert.alert("Success", "Workout scheduled successfully!");
      // dispatch action to update state.schedule
      updateScheduleForDates(dispatch, user_id, workoutDates);

      hideDialog();
    } catch (error) {
      console.error("Error saving activity:", error);
      Alert.alert("Error", "There was an error scheduling the workouts.");
    }
  };

  return <Button onPress={handleSchedule}>Schedule</Button>;
};

export default ScheduleWorkoutButton;

const styles = StyleSheet.create({
  // Add any styles you need here
});
