import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { formatFriendlyDate } from "../components/utils/dateUtils";
import {
  CoachedAthleteProfile,
  CoachStatus,
  initialUserProfile,
} from "../types/user";
import ContactProfile from "../components/User/ContactProfile";
import RemoveAthleteDialog from "../components/Coaches/ConfirmRemove";
import { useProfile } from "../provider/ProfileProvider";
import { fetchCoachedAthleteData } from "../provider/ProfileProvider/actions";

type MyAthletesProps = NativeStackScreenProps<
  MainStackParamList,
  "My Athlete Details"
>;

const MyAthleteDetails: React.FC<MyAthleteDetailsProps> = ({
  route,
  navigation,
}) => {
  const { profile } = route.params as { profile: CoachedAthleteProfile };
  const { state, dispatch } = useProfile();
  const [removeConfirmationVisible, setRemoveConfirmationVisible] =
    useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  // create a null function for setProfile
  const setProfile = () => {};
  // const navigation = useNavigation();
  const isCoached = profile.status === CoachStatus.ACCEPTED;
  const handleRemoveAthlete = () => {
    // Logic to handle deleting account
    setRemoveConfirmationVisible(true);
  };

  const coachedStatusText = () => {
    if (profile.status === CoachStatus.PENDING) {
      return `Invitation pending since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else if (profile.status === CoachStatus.ACCEPTED) {
      return `Coached by you since ${formatFriendlyDate(
        profile.updated_at,
        false
      )}`;
    } else {
      return `Coached status unknown`;
    }
  };

  const fetchAthletes = async () => {
    await fetchCoachedAthleteData(dispatch);
  };

  useEffect(() => {
    if (isRemoved) {
      fetchAthletes(); // Fetch the list of athletes
      navigation.goBack();
    }
  }, [isRemoved]);

  return (
    <>
      <ScreenWrapper>
        <View>
          <List.Item
            title={coachedStatusText()}
            onPress={handleRemoveAthlete}
            titleStyle={isCoached ? { color: "green" } : { color: "orange" }}
          />
        </View>
        {isCoached ? (
          <ContactProfile profile={profile} setProfile={setProfile} />
        ) : (
          <List.Section>
            <List.Subheader>Profile</List.Subheader>
            <TextInput
              label="Email"
              value={profile.email}
              editable={false}
              disabled={true}
              mode="outlined"
              style={styles.input}
            />
          </List.Section>
        )}
        <View>
          <List.Section>
            <List.Item
              title={
                isCoached ? "Remove Athlete from Roster" : "Cancel Invitation"
              }
              onPress={handleRemoveAthlete}
              titleStyle={{ color: "red" }}
              style={styles.input}
            />
          </List.Section>
        </View>
      </ScreenWrapper>
      <RemoveAthleteDialog
        removeConfirmationVisible={removeConfirmationVisible}
        setRemoveConfirmationVisible={setRemoveConfirmationVisible}
        coachId={profile.coach_id}
        athleteId={profile.athlete_id}
        setIsRemoved={setIsRemoved}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 16,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});

export default MyAthleteDetails;
