import { Database } from "./supabase";
// import { types as activityTypes } from "../constants/activityTypes";

export enum DisplayDistanceUnit {
  LONG = "long",
  SHORT = "short",
}

export enum DisplaySpeedUnits {
  MINUTES_PER_DISTANCE = "minutes_per_distance",
  SWIM_PACE = "swim_pace",
  DISTANCE_PER_HOUR = "distance_per_hour",
}

export enum ActivityIcon {
  RUN = "run",
  SWIM = "swim",
  RIDE = "bike",
  STRENGTH = "weight-lifter",
  WORKOUT = "heart",
  WALK = "walk",
  ELLIPTICAL = "ski-cross-country",
  DEEP_WATER_RUN = "pool",
  YOGA = "yoga",
  RECOVERY = "heart",
  TRIATHLON = "dots-horizontal-circle-outline",
  MULTISPORT = "dots-horizontal-circle-outline",
  OTHER = "heart",
}

export enum ActivityTypeValue {
  RUN = 1,
  SWIM = 2,
  RIDE = 3,
  STRENGTH = 4,
  WORKOUT = 5,
  WALK = 6,
  ELLIPTICAL = 7,
  DEEP_WATER_RUN = 8,
  YOGA = 9,
  RECOVERY = 10,
  TRIATHLON = 11,
  MULTISPORT = 11,
  OTHER = 12,
}

export enum ActivityTypeLabel {
  RUN = "Run",
  SWIM = "Swim",
  RIDE = "Ride",
  STRENGTH = "Strength",
  WORKOUT = "Workout",
  WALK = "Walk",
  ELLIPTICAL = "Elliptical",
  DEEP_WATER_RUN = "Deep Water Run",
  YOGA = "Yoga",
  RECOVERY = "Recovery",
  TRIATHLON = "Triathlon",
  MULTISPORT = "Multisport",
  OTHER = "Other",
}

export interface BaseActivityType {
  label: ActivityTypeLabel;
  value: ActivityTypeValue;
  icon: ActivityIcon;
  displayDistanceUnit: DisplayDistanceUnit;
  displaySpeedUnits: DisplaySpeedUnits;
}

export const RunActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RUN,
  value: ActivityTypeValue.RUN,
  icon: ActivityIcon.RUN,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const SwimActivityType: BaseActivityType = {
  label: ActivityTypeLabel.SWIM,
  value: ActivityTypeValue.SWIM,
  icon: ActivityIcon.SWIM,
  displayDistanceUnit: DisplayDistanceUnit.SHORT,
  displaySpeedUnits: DisplaySpeedUnits.SWIM_PACE,
};

export const RideActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RIDE,
  value: ActivityTypeValue.RIDE,
  icon: ActivityIcon.RIDE,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.DISTANCE_PER_HOUR,
};

export const StrengthActivityType: BaseActivityType = {
  label: ActivityTypeLabel.STRENGTH,
  value: ActivityTypeValue.STRENGTH,
  icon: ActivityIcon.STRENGTH,
  displayDistanceUnit: DisplayDistanceUnit.SHORT,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const WorkoutActivityType: BaseActivityType = {
  label: ActivityTypeLabel.WORKOUT,
  value: ActivityTypeValue.WORKOUT,
  icon: ActivityIcon.WORKOUT,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const WalkActivityType: BaseActivityType = {
  label: ActivityTypeLabel.WALK,
  value: ActivityTypeValue.WALK,
  icon: ActivityIcon.WALK,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const EllipticalActivityType: BaseActivityType = {
  label: ActivityTypeLabel.ELLIPTICAL,
  value: ActivityTypeValue.ELLIPTICAL,
  icon: ActivityIcon.ELLIPTICAL,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const DeepWaterRunActivityType: BaseActivityType = {
  label: ActivityTypeLabel.DEEP_WATER_RUN,
  value: ActivityTypeValue.DEEP_WATER_RUN,
  icon: ActivityIcon.DEEP_WATER_RUN,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const YogaActivityType: BaseActivityType = {
  label: ActivityTypeLabel.YOGA,
  value: ActivityTypeValue.YOGA,
  icon: ActivityIcon.YOGA,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const RecoveryActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RECOVERY,
  value: ActivityTypeValue.RECOVERY,
  icon: ActivityIcon.RECOVERY,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const MultisportActivityType: BaseActivityType = {
  label: ActivityTypeLabel.MULTISPORT,
  value: ActivityTypeValue.MULTISPORT,
  icon: ActivityIcon.MULTISPORT,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.DISTANCE_PER_HOUR,
};

export const TriathlonActivityType: BaseActivityType = {
  label: ActivityTypeLabel.TRIATHLON,
  value: ActivityTypeValue.TRIATHLON,
  icon: ActivityIcon.TRIATHLON,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.DISTANCE_PER_HOUR,
};

export const OtherActivityType: BaseActivityType = {
  label: ActivityTypeLabel.OTHER,
  value: ActivityTypeValue.OTHER,
  icon: ActivityIcon.OTHER,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

// Define a union type for all specific activity types
export type ActivityType =
  | typeof RunActivityType
  | typeof SwimActivityType
  | typeof RideActivityType
  | typeof StrengthActivityType
  | typeof WorkoutActivityType
  | typeof WalkActivityType
  | typeof EllipticalActivityType
  | typeof DeepWaterRunActivityType
  | typeof YogaActivityType
  | typeof RecoveryActivityType
  | typeof TriathlonActivityType
  | typeof MultisportActivityType
  | typeof OtherActivityType;

type ActivityDataBase = Database["public"]["Tables"]["activities"]["Row"];
// Define the properties to be made optional in the front end
type OptionalActivityDataFields = {
  created_at?: string | null;
  deleted_at?: string | null;
  id?: string;
  updated_at?: string | null;
};
// Combine the base type omitting the optional fields, then union with the optional fields
export type ActivityData = Omit<
  ActivityDataBase,
  keyof OptionalActivityDataFields
> &
  OptionalActivityDataFields & {
    type: ActivityTypeValue;
  };

export type ActivityRecordingData =
  Database["public"]["Tables"]["activity_recordings"]["Row"];

export type ActivityTotals = {
  total_count: number;
  total_count_planned: number;
  total_count_missed: number;
  total_count_planned_incomplete: number;
  total_time: number;
  total_time_planned: number;
  total_time_missed: number;
  total_time_planned_incomplete: number;
  total_distance: number;
  total_distance_planned: number;
  total_distance_missed: number;
  total_distance_planned_incomplete: number;
  total_distance_completed?: number;
  type?: ActivityTypeValue;
  date?: string;
  daily?: Record<string, any>;
  weekly?: Record<string, any>;
};

export type ActivityTotalsBase = {
  total_count: number;
  total_time: number;
  total_distance: number;
  total_count_planned: number;
  total_time_planned: number;
  total_distance_planned: number;
  total_count_missed: number;
  total_time_missed: number;
  total_distance_missed: number;
  total_count_planned_incomplete: number;
  total_time_planned_incomplete: number;
  total_distance_planned_incomplete: number;
  total_distance_completed?: number;
  type?: ActivityTypeValue;
  date?: string;
};

export type ActivityTotalsCollection = {
  [date: string]: ActivityTotalsBase;
};

export interface WeeklyActivityTotalsCollection extends ActivityTotals {
  weekly: ActivityTotalsCollection;
}

export interface DailyActivityTotalsCollection extends ActivityTotals {
  daily: ActivityTotalsCollection;
}
export interface WeeklyActivityTotalsPerActivityCollection {
  [key: string]: WeeklyActivityTotalsCollection;
}

export interface DailyActivityTotalsPerActivityCollection {
  [key: string]: DailyActivityTotalsCollection;
}

export type ActivityTotalsPerActivity = {
  [activityType in ActivityTypeLabel | "total"]: ActivityTotals;
};

export type ActivityTotalsPerDay = {
  [date: string]: ActivityTotals;
};

export type ActivityTotalsPerWeek = {
  [date: string]: ActivityTotalsPerWeek;
};

export type ActivityStatus = {
  label: string;
  color: string;
  totalKey: TotalType;
};

export interface TotalTypeMap {
  duration: string;
  distance: string;
  count: string;
}

export enum TotalType {
  DURATION = "duration",
  DISTANCE = "distance",
  COUNT = "count",
}

// Define a union type for all specific activity types
export type EventActivityType =
  | typeof RunActivityType
  | typeof SwimActivityType
  | typeof RideActivityType
  | typeof WalkActivityType
  | typeof TriathlonActivityType
  | typeof MultisportActivityType
  | typeof OtherActivityType;

export enum RunEventDistance {
  ONE_THOUSAND = "1000m",
  ONE_THOUSAND_FIVE_HUNDRED = "1500m",
  ONE_MILE = "1 mile",
  THREE_THOUSAND = "3000m",
  THREE_THOUSAND_STEEPLECHASE = "3000m steeplechase",
  FIVE_KM = "5km",
  TEN_THOUSAND = "10000m",
  TEN_KM = "10km",
  HALF_MARATHON = "Half marathon",
  MARATHON = "Marathon",
  XC_5KM = "Cross country 5km",
  XC_8KM = "Cross country 8km",
  XC_10KM = "Cross country 10km",
  OTHER = "Other",
}

export const RunEventDistances: Record<
  RunEventDistance,
  { label: string; distanceInMeters: number; value: RunEventDistance }
> = {
  [RunEventDistance.ONE_THOUSAND]: {
    label: "1000m",
    distanceInMeters: 1000,
    value: RunEventDistance.ONE_THOUSAND,
  },
  [RunEventDistance.ONE_THOUSAND_FIVE_HUNDRED]: {
    label: "1500m",
    distanceInMeters: 1500,
    value: RunEventDistance.ONE_THOUSAND_FIVE_HUNDRED,
  },
  [RunEventDistance.ONE_MILE]: {
    label: "1 mile",
    distanceInMeters: 1609.34,
    value: RunEventDistance.ONE_MILE,
  },
  [RunEventDistance.THREE_THOUSAND]: {
    label: "3000m",
    distanceInMeters: 3000,
    value: RunEventDistance.THREE_THOUSAND,
  },
  [RunEventDistance.THREE_THOUSAND_STEEPLECHASE]: {
    label: "3000m steeplechase",
    distanceInMeters: 3000,
    value: RunEventDistance.THREE_THOUSAND_STEEPLECHASE,
  },
  [RunEventDistance.FIVE_KM]: {
    label: "5km",
    distanceInMeters: 5000,
    value: RunEventDistance.FIVE_KM,
  },
  [RunEventDistance.TEN_THOUSAND]: {
    label: "10000m",
    distanceInMeters: 10000,
    value: RunEventDistance.TEN_THOUSAND,
  },
  [RunEventDistance.TEN_KM]: {
    label: "10km",
    distanceInMeters: 10000,
    value: RunEventDistance.TEN_KM,
  },
  [RunEventDistance.HALF_MARATHON]: {
    label: "Half marathon",
    distanceInMeters: 21097.5,
    value: RunEventDistance.HALF_MARATHON,
  },
  [RunEventDistance.MARATHON]: {
    label: "Marathon",
    distanceInMeters: 42195,
    value: RunEventDistance.MARATHON,
  },
  [RunEventDistance.XC_5KM]: {
    label: "Cross country 5km",
    distanceInMeters: 5000,
    value: RunEventDistance.XC_5KM,
  },
  [RunEventDistance.XC_8KM]: {
    label: "Cross country 8km",
    distanceInMeters: 8000,
    value: RunEventDistance.XC_8KM,
  },
  [RunEventDistance.XC_10KM]: {
    label: "Cross country 10km",
    distanceInMeters: 10000,
    value: RunEventDistance.XC_10KM,
  },
  [RunEventDistance.OTHER]: {
    label: "Other",
    distanceInMeters: 0,
    value: RunEventDistance.OTHER,
  },
};

export const RunEventDistancesList = Object.values(RunEventDistances);
