import React from "react";
import { View, StyleSheet } from "react-native";
import { Card, Paragraph, Icon } from "react-native-paper";
import { ICONS } from "../../constants/icons";
import { NoteData } from "../../types/note";

interface NoteCardProps {
  note: NoteData;
}

const NoteCard: React.FC<NoteCardProps> = ({
  note, // Add isCompleted prop
}) => {
  return (
    <Card style={{ width: "100%" }}>
      <Card.Title
        title={note.title}
        left={(props) => <Icon source={ICONS.NOTE} size={24} />}
      />
      <Card.Content>
        {note.description && note.description.length > 0 && (
          <Paragraph numberOfLines={3}>{note.description}</Paragraph>
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  durationContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
    opacity: 0.8,
  },
  metricText: {
    opacity: 0.8,
  },
  type: {
    fontSize: 12,
  },
});

export default NoteCard;
