import React, { useState } from "react";
import { StyleSheet } from "react-native";
import {
  Menu,
  Portal,
  Dialog,
  Paragraph,
  Button,
  Appbar,
} from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ICONS } from "../../constants/icons";
import { SCREEN_NAMES } from "../../constants/screenNames";
import { deleteEvent } from "../../api/events";
import { updateScheduleForDates } from "../../provider/ScheduleProvider/actions";
import { getCurrentViewUserId } from "../../provider/ProfileProvider/selectors";
import { useSchedule } from "../../provider/ScheduleProvider";
import { useProfile } from "../../provider/ProfileProvider";
import DeleteConfirmation from "../utils/DeleteConfirmation";
import showAlert from "../utils/showAlert";

const EventAppBarMenu = () => {
  const { dispatch } = useSchedule();
  const { state: profileState } = useProfile();
  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const navigation = useNavigation();
  const route = useRoute();

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const handleEdit = () => {
    closeMenu();
    navigation.navigate(SCREEN_NAMES.EDIT_EVENT, {
      event: route.params.event,
    });
  };

  const handleDuplicate = () => {
    closeMenu();
    const eventToDuplicate = route.params.event;
    const newEvent = { ...eventToDuplicate };
    newEvent.id = null; // Set id to null to create a new activity
    navigation.navigate(SCREEN_NAMES.EDIT_EVENT, {
      event: newEvent,
      isDuplicateMode: true,
    });
  };

  const handleDelete = () => {
    closeMenu();
    setDeleteConfirmationVisible(true); // Show delete confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      // throw a test error
      // throw new Error("Test error");

      if (route.params) {
        setDeleteError(null);
        await deleteEvent(route.params.event.id);
        const user_id = getCurrentViewUserId(profileState);
        if (user_id) {
          await updateScheduleForDates(
            dispatch,
            user_id,
            route.params.event.event_date
          );
        } else {
          throw new Error("User ID is null");
        }
      }
      navigation.goBack();
    } catch (error: any) {
      setDeleteError(error);
      setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
      showAlert(
        "Error",
        `An error occurred while trying to delete ${route.params.event.title}.`
      );
      console.error("Error deleting event:", error);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
  };

  return (
    <>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={<Appbar.Action icon="dots-vertical" onPress={openMenu} />}
      >
        <Menu.Item onPress={handleEdit} title="Edit" leadingIcon={ICONS.EDIT} />
        <Menu.Item
          onPress={handleDuplicate}
          title="Duplicate"
          leadingIcon={ICONS.DUPLICATE}
        />
        <Menu.Item
          onPress={handleDelete}
          title="Delete"
          leadingIcon={ICONS.DELETE}
        />
      </Menu>
      {deleteConfirmationVisible && (
        <DeleteConfirmation
          message={`Are you sure you want to delete ${route.params.event.title}? This can't be undone.`}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
      {/* <Portal>
        <Dialog visible={deleteConfirmationVisible} onDismiss={cancelDelete}>
          <Dialog.Title>Confirmation</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Are you sure you want to delete this event?</Paragraph>
            {deleteError && (
              <Paragraph style={styles.errorText}>
                An error occurred while trying to delete the event.
              </Paragraph>
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancelDelete}>Cancel</Button>
            <Button onPress={confirmDelete}>Delete</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal> */}
    </>
  );
};

const styles = StyleSheet.create({
  errorText: {
    color: "red",
  },
});

export default EventAppBarMenu;
