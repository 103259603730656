import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  List,
  TextInput,
  useTheme,
  Button,
  RadioButton,
  Text,
} from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useNavigation } from "@react-navigation/native";
import { fetchProfile, updateProfile } from "../api/users";
import { supabase } from "../initSupabase";
import { UserProfile, initialUserProfile } from "../types/user";
import ProfileForm from "../components/User/ProfileForm";
import { useProfile } from "../provider/ProfileProvider";
import { fetchInitialProfile } from "../provider/ProfileProvider/actions";
import Loading from "./utils/Loading";

export default function AccountSettings() {
  const [profile, setProfile] = useState<UserProfile>(initialUserProfile); // State to hold the users profile
  const navigation = useNavigation();
  const { state, dispatch } = useProfile();

  const handleChangePassword = () => {
    // Logic to handle changing password
    navigation.navigate("ResetPassword");
  };

  const handleDeleteAccount = () => {
    // Logic to handle deleting account
  };

  // // Fetch user data from Supabase
  // const fetchUserData = async () => {
  //   const { data, error } = await supabase.auth.getSession();
  //   if (data.session.user.email) {
  //     setEmail(data.session.user.email);
  //   } else {
  //     setEmail("No email found");
  //   }
  // };

  const handleSaveChanges = async () => {
    try {
      const user_id = (await supabase.auth.getSession()).data.session?.user.id;

      // Update existing profile
      if (user_id) {
        const data = await updateProfile(user_id, profile);
      }

      // Update the global state with the latest profile data
      fetchInitialProfile(dispatch);
      alert("Profile updated successfully!");

      navigation.goBack();
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  useEffect(() => {
    if (state.profile && !state.isLoadingProfile) {
      setProfile(state.profile);
    }
  }, [state.profile, state.isLoadingProfile]);

  if (state.isLoadingProfile) {
    return <Loading />;
  }

  return (
    <ScreenWrapper withForms={true}>
      <ProfileForm profile={profile} setProfile={setProfile} disabled={false} />
      <View style={styles.container}>
        <List.Section>
          <List.Subheader>Account</List.Subheader>
          <List.Item
            title="Change Password"
            onPress={handleChangePassword}
            style={styles.input}
          />
          <List.Item
            title="Delete Account"
            onPress={handleDeleteAccount}
            titleStyle={{ color: "red" }}
            style={styles.input}
          />
        </List.Section>
        <View style={styles.buttonContainer}>
          <Button
            mode="contained"
            onPress={handleSaveChanges}
            style={styles.saveButton}
          >
            Save Changes
          </Button>
        </View>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 8,
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 16,
  },
  saveButton: {
    width: 150,
  },
  container: {
    flex: 1,
    padding: 16,
  },
});
