import {
  CoachProfile,
  CoachedAthleteProfile,
  UserProfile,
} from "../../types/user";

export function getUserName(
  profile: UserProfile | CoachedAthleteProfile | CoachProfile | null | undefined
) {
  if (profile?.first_name || profile?.last_name) {
    return `${profile?.first_name || ""} ${profile?.last_name || ""}`.trim();
  } else if (profile?.email) {
    return profile.email;
  } else {
    return "Unnamed User";
  }
}

export function getUserInitials(
  profile: UserProfile | CoachedAthleteProfile | CoachProfile | null | undefined
) {
  const firstNameInitial = profile?.first_name?.charAt(0) || "";
  const lastNameInitial = profile?.last_name?.charAt(0) || "";

  if (!firstNameInitial && !lastNameInitial && profile?.email) {
    return profile.email.charAt(0).toUpperCase();
  }

  return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
}

export function isStravaConnected(profile: UserProfile) {
  return profile?.strava_api_data?.connection_status === "connected";
}

export const getSexLabel = (sex: number): string => {
  switch (sex) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Other/prefer not to set";
    default:
      return "Unknown";
  }
};
