import { Platform } from "react-native";

export const MORE_ICON =
  Platform.OS === "ios" ? "dots-horizontal" : "dots-vertical";

export const MERGE_ICON = "vector-union";
export const UNMERGE_ICON = "vector-combine";
export const DUPLICATE_ICON = "content-copy";
export const DELETE_ICON = "delete";

export const ICONS = {
  MORE: Platform.OS === "ios" ? "dots-horizontal" : "dots-vertical",
  DUPLICATE: "content-copy",
  EDIT: "pencil",
  DELETE: "delete",
  MERGE: "vector-union",
  UNMERGE: "vector-combine",
  NOTE: "note-outline",
  EVENT: "trophy",
  WORKOUT_LIBRARY: "bookshelf",
  WORKOUT: "pulse",
  CATEGORY: "folder",
  SCHEDULE: "calendar",
  ADD_TO_SCHEDULE: "calendar-plus",
  EVENT_DATE: "calendar-star",
  EVENT_CHECKLIST: "checkbox-multiple-marked",
  ADD: "plus",
  USER_VIEW_CONTEXT: "account-filter",
  ATTACHMENT: "attachment",
  CHECKBOX_MARKED: "checkbox-marked",
  CHECKBOX_UNMARKED: "checkbox-blank-outline",
  FILE: "file",
  IMAGE: "image",
  LINK: "link",
  PDF: "file-pdf-box",
};
