import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Text } from "react-native-paper";
import Victory from "./victory/victory";
import { dateToWeekdayAbbreviation } from "../utils/dateUtils";
import { activityStatuses } from "../../constants/activityTypes";
import { ActivityTotals, TotalType } from "../../types/activity";
import { convertTotalsDataToKilometers } from "../utils/activityUtils";

const VictoryBar = Victory.VictoryBar;
const VictoryChart = Victory.VictoryChart;
const VictoryTheme = Victory.VictoryTheme;
const VictoryAxis = Victory.VictoryAxis;
const VictoryLabel = Victory.VictoryLabel;
const VictoryStack = Victory.VictoryStack;
const VictoryTooltip = Victory.VictoryTooltip;
const VictoryVoronoiContainer = Victory.VictoryVoronoiContainer;

interface DataPoint {
  category: string;
  value: number;
}

interface BarChartProps {
  data: ActivityTotals[];
  labelKey: string;
  valueKey: number;
  totalType: TotalType;
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}:${minutes.toString().padStart(2, "0")}`;
};

const formatTooltipLabel = (datum: any, totalType: string) => {
  if (totalType === TotalType.DISTANCE) {
    return `${datum._y.toFixed(1)} km`;
  } else if (totalType === TotalType.DURATION) {
    return formatDuration(datum._y); // Assuming formatDuration is a function that formats duration
  } else {
    return `${datum._y}`; // Default case for count or other types
  }
};

const BarChart: React.FC<BarChartProps> = ({
  data,
  labelKey = "label",
  totalType = TotalType.COUNT,
}) => {
  // Define tick format and tick count based on totalType
  let tickFormat = (t: number) => (t === Math.floor(t) ? t.toString() : "");

  // if totalType is distance, convert data in m to km
  // (total_distance, total_distance_completed, total_distance_missed, total_distance_planned, total_distance_planned_incomplete)
  if (totalType === TotalType.DISTANCE) {
    data = convertTotalsDataToKilometers(data);
  }

  if (totalType === TotalType.DISTANCE) {
    tickFormat = (t: number) => `${t} km`;
    // tickCount = 5;
  } else if (totalType === TotalType.DURATION) {
    tickFormat = (t: number) => formatDuration(t);
    // tickCount = 4;
  }

  return (
    <View style={styles.container}>
      <VictoryChart
        width={350}
        height={250}
        theme={VictoryTheme.material}
        domainPadding={{ x: 15 }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          tickFormat={(x) => dateToWeekdayAbbreviation(x)}
          style={{
            ticks: { size: 0 }, // Set the tick size to 0 to hide tick lines
          }}
        />
        <VictoryAxis
          dependentAxis
          //tickCount={tickCount} // Adjust the number of ticks as needed
          minDomain={{ y: 0 }}
          tickFormat={tickFormat}
        />
        <VictoryStack>
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.completed.totalKey[totalType]} // totalType completed
            barRatio={1.2}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.completed.color, // Set the completed color here
              },
            }}
          />
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.missed.totalKey[totalType]} // totalType missed
            barRatio={1.2}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.missed.color, // Set the missed color here
              },
            }}
          />
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.planned.totalKey[totalType]} // Replace with totalType planned
            barRatio={1.2}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.planned.color, // Set the color here
              },
            }}
          />
        </VictoryStack>
      </VictoryChart>
    </View>
  );
};

export default BarChart;
