import * as React from "react";
import { StyleSheet } from "react-native";
import {
  Button,
  Dialog,
  Portal,
  HelperText,
  Text,
  TextInput,
} from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import { sendAthleteInvitationRequestToSupabase } from "../../api/coaches";
import { useState } from "react";
import Loading from "../../screens/utils/Loading";
import { Invites } from "../../types/coach";

interface InviteAthleteProps {
  visible: boolean;
  hideDialog: () => void;
}

const InviteAthletesDialog: React.FC<InviteAthleteProps> = ({
  visible,
  hideDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailAddressesRawText, setEmailAddressesRawText] =
    useState<string>("");
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [sent, setSent] = useState<boolean>(false);
  const [inviteStatus, setInviteStatus] = useState<Invites>([]);

  const parseEmailAddresses = (rawText: string) => {
    setEmailAddressesRawText(rawText);
    setEmailAddresses(
      rawText
        .split("\n")
        .map((email) => email.trim())
        .filter((email) => email !== "")
    );
    setHasErrors(invalidEmail() || tooMany());
    let errorTexts = [];
    if (invalidEmail()) {
      errorTexts.push("Email address is invalid.");
    }
    if (tooMany()) {
      errorTexts.push("Maximum 10 email addresses.");
    }
    setErrorText(errorTexts.join(" "));
  };

  const invalidEmail = () => {
    return !emailAddresses.every((email) => email.includes("@"));
  };

  const tooMany = () => {
    return emailAddresses.length > 10;
  };

  const confirmInviteAthlete = async () => {
    if (!hasErrors) {
      setSent(false);
      setLoading(true);
      try {
        // parse comma separated email addresses into an array
        const { data, error } = await sendAthleteInvitationRequestToSupabase(
          emailAddresses
        );
        if (error) {
          // Handle error
          console.error("Error:", error);
        } else {
          // Handle data
          setLoading(false);
          setSent(true);
          console.log("data", data);
          setInviteStatus(data);
        }
      } catch (error) {
        console.error("Error sending coaching invitation:", error);
        // Handle any errors here
      } finally {
      }
    }
  };

  const handleCancel = () => {
    // clean up
    setEmailAddressesRawText("");
    setEmailAddresses([]);
    // dispatch({ type: "FETCH_PROFILE" });

    hideDialog();
  };

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>Invite athletes to coach</Dialog.Title>
        <Dialog.Content>
          {loading ? (
            <Loading />
          ) : (
            <>
              <TextInput
                label="Email"
                value={emailAddressesRawText}
                onChangeText={(text) => parseEmailAddresses(text)}
                multiline
              />
              <HelperText type="error" visible={hasErrors}>
                {errorText}
              </HelperText>
              <HelperText type="info" visible={true}>
                Enter a single email address or paste up to 10 line-separated
                email addresses.
              </HelperText>
              {emailAddresses.length > 0 && !sent && (
                <Text>Send invites to:</Text>
              )}
              {!sent &&
                emailAddresses.map((email, index) => <Text>{email}</Text>)}
              {sent &&
                Array.isArray(inviteStatus) &&
                inviteStatus.map((invite, index) => (
                  <Text style={{ color: invite.success ? "green" : "red" }}>
                    {invite.success ? (
                      <MaterialIcons name="check" size={24} color="green" />
                    ) : (
                      <MaterialIcons name="close" size={24} color="red" />
                    )}{" "}
                    {invite.email}{" "}
                    {invite.success ? "sent" : "not sent. " + invite.error}
                  </Text>
                ))}
            </>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={handleCancel}>{sent ? "Done" : "Cancel"}</Button>
          <Button onPress={confirmInviteAthlete}>
            {loading ? "Sending..." : "Send"}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    margin: 8,
  },
});

export default InviteAthletesDialog;
