export const SUPPORT_URL = "https://www.chironapp.com/support-center/";
export const RUNNING_CALCULATOR_URL = "https://calculator.chironapp.com/";
export const TERMS_URL = "https://www.chironapp.com/terms-of-service/";
export const PRIVACY_URL = "https://www.chironapp.com/privacy-policy/";
export const DEV_FORGET_PASSWORD_URL =
  "http://dev-remote-07.chironapp.com:19006/reset-password/";
export const FORGET_PASSWORD_URL = "http://test.chironapp.com/reset-password/";
export const STRAVA_AUTHORIZATION_URL =
  "https://www.strava.com/oauth/authorize";
export const STRAVA_ACTIVITIES_BASE_URL = "https://www.strava.com/activities/";
