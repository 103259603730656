import { Schedule } from "../../types/schedule";

export interface ScheduleState {
  isLoading: boolean;
  schedule: Schedule;
  focusDate: string | Date;
  error: any;
}

export enum ScheduleActionTypes {
  SET_LOADING = "SET_LOADING",
  FETCH_SCHEDULE = "FETCH_SCHEDULE",
  FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS",
  FETCH_SCHEDULE_FAILURE = "FETCH_SCHEDULE_FAILURE",
  EXTEND_SCHEDULE = "EXTEND_SCHEDULE",
  EXTEND_SCHEDULE_SUCCESS = "EXTEND_SCHEDULE_SUCCESS",
  EXTEND_SCHEDULE_FAILURE = "EXTEND_SCHEDULE_FAILURE",
  UPDATE_SCHEDULE = "UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULE_FAILURE = "UPDATE_SCHEDULE_FAILURE",
  SET_FOCUS_DATE = "SET_FOCUS_DATE",
}

export type SetLoadingAction = {
  type: ScheduleActionTypes.SET_LOADING;
  isLoading: boolean;
};

export type FetchScheduleAction = {
  type: ScheduleActionTypes.FETCH_SCHEDULE;
};

export type FetchScheduleSuccessAction = {
  type: ScheduleActionTypes.FETCH_SCHEDULE_SUCCESS;
  schedule: Schedule;
};

export type FetchScheduleFailureAction = {
  type: ScheduleActionTypes.FETCH_SCHEDULE_FAILURE;
  error: any;
};

export type ExtendScheduleAction = {
  type: ScheduleActionTypes.EXTEND_SCHEDULE;
};

export type ExtendScheduleSuccessAction = {
  type: ScheduleActionTypes.EXTEND_SCHEDULE_SUCCESS;
  schedule: Schedule;
};

export type ExtendScheduleFailureAction = {
  type: ScheduleActionTypes.EXTEND_SCHEDULE_FAILURE;
  error: any;
};

export type UpdateScheduleAction = {
  type: ScheduleActionTypes.UPDATE_SCHEDULE;
};

export type UpdateScheduleSuccessAction = {
  type: ScheduleActionTypes.UPDATE_SCHEDULE_SUCCESS;
  schedule: Schedule;
};

export type UpdateScheduleFailureAction = {
  type: ScheduleActionTypes.UPDATE_SCHEDULE_FAILURE;
  error: any;
};

export type SetFocusDateAction = {
  type: ScheduleActionTypes.SET_FOCUS_DATE;
  focusDate: string | Date;
};

export type ScheduleAction =
  | SetLoadingAction
  | FetchScheduleAction
  | FetchScheduleSuccessAction
  | FetchScheduleFailureAction
  | ExtendScheduleAction
  | ExtendScheduleSuccessAction
  | ExtendScheduleFailureAction
  | UpdateScheduleAction
  | UpdateScheduleSuccessAction
  | UpdateScheduleFailureAction
  | SetFocusDateAction;
