import React, { useEffect, useState } from "react";
import { View, Linking, StyleSheet, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, List, Avatar } from "react-native-paper";
import TouchableActivityCard from "../components/Activity/TouchableActivityCard";
import { hasPendingCoachRequests } from "../components/utils/coaches";
import TotalsCard from "../components/TotalsCard";
import {
  formatFriendlyDate,
  getCountdownString,
} from "../components/utils/dateUtils";
import ScreenWrapper from "../components/ScreenWrapper";
import PendingCoachInvitationBanner from "../components/Coaches/PendingCoachInvitationBanner";
import TouchableEventCard from "../components/Event/TouchableEventCard";
import Loading from "./utils/Loading";
import { useSchedule } from "../provider/ScheduleProvider";
import { useProfile } from "../provider/ProfileProvider";
import {
  findLatestCompletedWorkout,
  findNextWorkout,
  findNextEvent,
} from "../provider/ScheduleProvider/selectors";
import { getCurrentViewUserProfile } from "../provider/ProfileProvider/selectors";
import { ScheduledEvent, ScheduledWorkout } from "../types/schedule";
import { CoachedAthleteProfile, UserProfile } from "../types/user";
import NoEventCard from "../components/Event/NoEventCard";
import UserProfileCard from "../components/Home/UserProfileCard";
import UserProfileListItem from "../components/Home/UserProfileListItem";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const { state: scheduleState } = useSchedule();
  const { state: profileState } = useProfile();
  const [nextEvent, setNextEvent] = useState<ScheduledEvent | null>(null); // State to hold the next event
  const [latestCompletedActivity, setLatestCompletedActivity] =
    useState<ScheduledWorkout | null>(null); // State to hold the latest completed activity
  const [nextActivity, setNextActivity] = useState<ScheduledWorkout | null>(
    null
  );
  const [pendingCoaches, setPendingCoaches] = useState<boolean>(
    hasPendingCoachRequests(profileState.coaches)
  ); // State to hold the pending coaches
  const [currentViewUserProfile, setCurrentViewUserProfile] = useState<
    UserProfile | CoachedAthleteProfile | null | undefined
  >(getCurrentViewUserProfile(profileState)); // State to hold the current view user profile

  // set profile data
  useEffect(() => {
    if (!profileState.isLoadingProfile) {
      setCurrentViewUserProfile(getCurrentViewUserProfile(profileState));
    }
  }, [profileState.isLoadingProfile, profileState.coachView]);

  // set schedule data
  useEffect(() => {
    // get workouts and events for the current user
    if (!scheduleState.isLoading) {
      // setNextEvent(nextEvent);
      setNextEvent(findNextEvent(scheduleState.schedule));
      // Set latest completed workout
      setLatestCompletedActivity(
        findLatestCompletedWorkout(scheduleState.schedule)
      );
      // Set next workout
      setNextActivity(findNextWorkout(scheduleState.schedule));
    }
  }, [scheduleState.isLoading]);

  // check if the user has any pending coach requests
  useEffect(() => {
    setPendingCoaches(hasPendingCoachRequests(profileState.coaches)); // Update the local state with the pending coaches
  }, [profileState.coaches]);

  if (scheduleState.isLoading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      {pendingCoaches && <PendingCoachInvitationBanner />}
      <View>
        {profileState.isLoadingProfile ? (
          <Loading />
        ) : (
          <View>
            <UserProfileCard profile={currentViewUserProfile} />
          </View>
        )}
      </View>
      {scheduleState.isLoading ? null /* TODO: make nice loading state */ : (
        <View>
          <View style={styles.titleThenCardContainer}>
            <View style={styles.heading}>
              <Text variant="titleMedium">
                Your next event
                {nextEvent
                  ? ", " + getCountdownString(nextEvent.event_date) + " to go"
                  : ""}
              </Text>
            </View>
            {nextEvent ? (
              <View>
                <TouchableEventCard event={nextEvent} navigation={navigation} />
              </View>
            ) : (
              <NoEventCard />
            )}
          </View>
          <View style={styles.heading}>
            <Text variant="titleMedium">
              Your next workout
              {nextActivity
                ? ", " + formatFriendlyDate(nextActivity.started_at)
                : ""}
            </Text>
          </View>
          {nextActivity ? (
            <View>
              <TouchableActivityCard
                activity={nextActivity}
                navigation={navigation}
              />
            </View>
          ) : (
            <Text>Nothing planned.</Text>
          )}
          <View style={styles.titleThenCardContainer}>
            <View style={styles.heading}>
              <Text variant="titleMedium">Latest activity</Text>
            </View>
            {latestCompletedActivity ? (
              <TouchableActivityCard
                activity={latestCompletedActivity}
                navigation={navigation}
              />
            ) : (
              <Text>Nothing completed.</Text>
            )}
          </View>
          <View style={styles.titleThenCardContainer}>
            <View style={styles.heading}>
              <Text variant="titleMedium">Progress</Text>
            </View>
            <TotalsCard />
          </View>
        </View>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  titleThenCardContainer: {},
  container: {
    flex: 1,

    width: "100%",
    paddingVertical: 8,
  },
  scrollViewContainer: {
    padding: 16,
  },
  heading: {
    marginBottom: 8,
    marginTop: 8,
  },
});
