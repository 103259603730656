import { supabase } from "../initSupabase";
import { CoachedAthleteProfile, CoachProfile } from "../types/user";

export async function fetchMyCoaches() {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    // Fetch activities and return the raw data
    const { data, error } = await supabase
      .from("coaches")
      .select("*")
      .eq("athlete_id", user_id);

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function fetchMyAthletes() {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    // Fetch activities and return the raw data
    const { data, error } = await supabase
      .from("coaches")
      .select("*")
      .eq("coach_id", user_id);

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function sendAthleteInvitationRequestToSupabase(
  emailAddresses: string[]
) {
  try {
    const user_id = (await supabase.auth.getSession()).data.session?.user.id;
    const { data, error } = await supabase.functions.invoke("coaches", {
      body: {
        action: "invite",
        user_id: user_id,
        athletes: emailAddresses,
      },
    });

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error sending coaching invitations:", error);
    throw error;
  }
}

export async function sendAcceptCoachRequestToSupabase(
  coachId: string,
  athleteId: string
) {
  try {
    const { data, error } = await supabase.functions.invoke("coaches", {
      body: {
        action: "accept",
        coach_id: coachId,
        athlete_id: athleteId,
      },
    });

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error sending accept coaching request:", error);
    throw error;
  }
}

export async function sendRemoveCoachRequestToSupabase(
  coachId: string,
  athleteId: string
) {
  try {
    const { data, error } = await supabase.functions.invoke("coaches", {
      body: {
        action: "remove",
        coach_id: coachId,
        athlete_id: athleteId,
      },
    });

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error sending remove coaching request:", error);
    throw error;
  }
}

export const fetchMyAthleteProfiles = async (): Promise<
  CoachedAthleteProfile[]
> => {
  try {
    const { data: myAthletesData } = await fetchMyAthletes();
    // Extract athlete_id values into an array
    const athleteIds = myAthletesData.map((athlete) => athlete.athlete_id);
    // Fetch activities and return the raw data
    const { data: profilesData, error } = await supabase
      .from("profiles")
      .select(
        "id, first_name, last_name, email, bio, sex, date_of_birth, location, account_type, created_at"
      )
      .in("id", athleteIds);
    const profiles = profilesData ? profilesData : [];
    // Join myAthletesData with profilesData
    const joinedData: CoachedAthleteProfile[] = profiles
      .map((profile): CoachedAthleteProfile | null => {
        const athlete = myAthletesData.find(
          (athlete) => athlete.athlete_id === profile.id
        );
        if (!athlete) {
          console.log("No matching athlete found for profile:", profile);
          return null;
        }
        return {
          ...profile,
          updated_at: athlete.updated_at,
          status: athlete.status,
          athlete_id: athlete.athlete_id,
          coach_id: athlete.coach_id,
        } as CoachedAthleteProfile;
      })
      .filter((profile): profile is CoachedAthleteProfile => profile !== null);

    if (error) {
      throw error;
    }

    return joinedData;
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
};

export const fetchMyCoachProfiles = async (): Promise<CoachProfile[]> => {
  try {
    const { data: myCoachesData } = await fetchMyCoaches();
    // Extract athlete_id values into an array
    const coachIds = myCoachesData.map((coach) => coach.coach_id);
    // Fetch activities and return the raw data
    // bio: string | null,
    // first_name: string | null,
    // last_name: string | null,
    // location: string | null,
    // email: string,
    // sex: integer | null,
    // account_type: string,
    const { data: profilesData, error } = await supabase
      .from("profiles")
      .select(
        "id, first_name, last_name, email, bio, sex, location, account_type, created_at"
      )
      .in("id", coachIds);
    const profiles = profilesData ? profilesData : [];
    // Join myAthletesData with profilesData
    const joinedData: CoachProfile[] = profiles
      .map((profile): CoachProfile | null => {
        const coach = myCoachesData.find(
          (coach) => coach.coach_id === profile.id
        );
        if (!coach) {
          console.log("No matching coach found for profile:", profile);
          return null;
        }
        return {
          ...profile,
          updated_at: coach.updated_at,
          status: coach.status,
          athlete_id: coach.athlete_id,
          coach_id: coach.coach_id,
        } as CoachProfile;
      })
      .filter((profile): profile is CoachProfile => profile !== null);

    if (error) {
      throw error;
    }
    return joinedData;
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
};
