import { ProfileAction, ProfileActionTypes, ProfileState } from "./types";

export default function profileReducer(
  state: ProfileState,
  action: ProfileAction
): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.SET_LOADING:
      return { ...state, isLoadingProfile: action.isLoadingProfile };
    case ProfileActionTypes.FETCH_PROFILE:
      return { ...state, isLoadingProfile: true };
    case ProfileActionTypes.FETCH_PROFILE_SUCCESS:
      return { ...state, isLoadingProfile: false, profile: action.profile };
    case ProfileActionTypes.FETCH_PROFILE_FAILURE:
      return { ...state, isLoadingProfile: false, error: action.error };
    case ProfileActionTypes.FETCH_ATHLETES:
      return { ...state, isLoadingAthletes: true };
    case ProfileActionTypes.FETCH_ATHLETES_SUCCESS:
      return { ...state, isLoadingAthletes: false, athletes: action.athletes };
    case ProfileActionTypes.FETCH_ATHLETES_FAILURE:
      return { ...state, isLoadingAthletes: false, error: action.error };
    case ProfileActionTypes.FETCH_COACHES:
      return { ...state, isLoadingCoaches: true };
    case ProfileActionTypes.FETCH_COACHES_SUCCESS:
      return { ...state, isLoadingCoaches: false, coaches: action.coaches };
    case ProfileActionTypes.FETCH_COACHES_FAILURE:
      return { ...state, isLoadingCoaches: false, error: action.error };
    case ProfileActionTypes.SET_COACH_VIEW:
      return { ...state, coachView: action.coachView };
    case ProfileActionTypes.SET_FETCH_WORKOUT_LIBRARY_REQUIRED:
      return {
        ...state,
        fetchWorkoutLibraryRequired: action.fetchWorkoutLibraryRequired,
      };
    default:
      return state;
  }
}
