import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { checkFileSize, formatFileSize, pickFileOrPhoto } from "./fileIO";
import { Text, Button } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import { set } from "lodash";

interface FilePickerProps {
  setSelectedFile: (file: DocumentPicker.DocumentResult | null) => void;
  selectedFile: DocumentPicker.DocumentResult | null;
}

type FileSizeCheck = { success: boolean; message?: string };

export const FilePicker: React.FC<FilePickerProps> = ({
  setSelectedFile,
  selectedFile,
}) => {
  const [fileSizeCheck, setFileSizeCheck] = useState<FileSizeCheck | null>(
    null
  );
  const handleFileSelection = async () => {
    const file = await pickFileOrPhoto();
    if (file) {
      const check = checkFileSize(file);
      console.log("File size check:", check);
      setFileSizeCheck(check);
      if (!check.success) {
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
      console.log("Selected file:", file);
    } else {
      console.log("No file selected");
    }
  };

  return (
    <View style={styles.container}>
      <Button onPress={handleFileSelection} mode="outlined">
        Select file
      </Button>
      {selectedFile && (
        <View style={{ marginTop: 16 }}>
          <Text>Selected File</Text>
          {selectedFile.type == "success" && (
            <>
              <Text>Name: {selectedFile.name}</Text>
              <Text>MIME Type: {selectedFile.mimeType}</Text>
              <Text>Size: {formatFileSize(selectedFile.size)}</Text>
            </>
          )}
        </View>
      )}
      {fileSizeCheck && !fileSizeCheck.success && (
        <>
          <Text style={styles.errorText}>{fileSizeCheck.message}</Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    padding: 8,
  },
  actions: {
    justifyContent: "flex-end",
  },
  errorText: {
    color: "red",
    marginTop: 8,
  },
});
