import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import { TextInput } from "react-native-paper";
import CustomPicker from "../input/CustomPicker";
import { eventActivityTypes } from "../../constants/activityTypes";
import {
  RunEventDistance,
  RunEventDistances,
  RunEventDistancesList,
} from "../../types/activity";
import DistanceInput from "../input/DistanceInput";

interface EventFormProps {
  selectedEventActivityType: number | null;
  setSelectedEventActivityType: (value: number | null) => void;
  selectedEventDistance: RunEventDistance | null;
  setSelectedEventDistance: (value: RunEventDistance | null) => void;
  distanceInMeters: number | null;
  setDistanceInMeters: (value: number | null) => void;
}

const EventTypeAndDistanceForm: React.FC<EventFormProps> = ({
  selectedEventActivityType,
  setSelectedEventActivityType,
  selectedEventDistance,
  setSelectedEventDistance,
  distanceInMeters,
  setDistanceInMeters,
}) => {
  useEffect(() => {
    console.log(eventActivityTypes);
    console.log("selectedEventActivityType", selectedEventActivityType);
    console.log("selectedEventDistance", selectedEventDistance);
    console.log("distanceInMeters", distanceInMeters);
  }, [selectedEventActivityType, selectedEventDistance, distanceInMeters]);

  useEffect(() => {
    console.log(
      "useEffect selectedEventActivityType or selectedEventDistance has changed",
      selectedEventActivityType
    );
    if (
      Number(selectedEventActivityType) === 1 &&
      selectedEventDistance !== RunEventDistance.OTHER &&
      selectedEventDistance !== null
    ) {
      console.log(
        "setting distance in meters absed on selectedEventDistance",
        RunEventDistances[selectedEventDistance].distanceInMeters
      );
      setDistanceInMeters(
        RunEventDistances[selectedEventDistance].distanceInMeters
      );
    }
  }, [selectedEventActivityType, selectedEventDistance]);

  return (
    <View>
      <CustomPicker
        title="Sport type"
        options={eventActivityTypes}
        selectedValue={selectedEventActivityType}
        onValueChange={(itemValue) => setSelectedEventActivityType(itemValue)}
      />
      {/* TODO: why the fuck is selectedEventActivityType changing to a string? It should always be a number */}
      {Number(selectedEventActivityType) === 1 && (
        <>
          <Text>
            Hello{" "}
            {selectedEventDistance !== null
              ? RunEventDistances[selectedEventDistance].distanceInMeters
              : ""}
          </Text>
          <CustomPicker
            title="Event distance"
            options={RunEventDistancesList}
            selectedValue={selectedEventDistance}
            onValueChange={(itemValue) => setSelectedEventDistance(itemValue)}
          />
        </>
      )}
      <TextInput
        label="Distance"
        style={styles.input}
        value={distanceInMeters?.toString()}
        onChangeText={(text) => setDistanceInMeters(parseInt(text))}
        keyboardType="decimal-pad"
        disabled={
          Number(selectedEventActivityType) === 1 &&
          selectedEventDistance !== RunEventDistance.OTHER
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
  },
  picker: {
    height: 50,
    width: "100%",
  },
});

export default EventTypeAndDistanceForm;
