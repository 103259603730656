import React, { useState, useReducer, useEffect } from "react";
import { Appbar } from "react-native-paper";
import { getHeaderTitle } from "@react-navigation/elements";
import {
  NavigationHelpers,
  ParamListBase,
  Route,
  useRoute,
} from "@react-navigation/native";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import CoachViewSelectorChip from "../components/Coaches/CoachViewSelectorChip";
import { formatDateToCustomFormat } from "../components/utils/dateUtils";
import { hasAthletes, isCoach } from "../components/utils/coaches";
import ActivityAppBarMenu from "../components/Activity/ActivityAppBarMenu";
import LibraryWorkoutAppBarMenu from "../components/WorkoutLibrary/LibraryWorkoutAppBarMenu";
import EventAppBarMenu from "../components/Event/EventAppBarMenu";
import { SCREEN_NAMES } from "../constants/screenNames";
import { useProfile } from "../provider/ProfileProvider";
import { useSchedule } from "../provider/ScheduleProvider";

export type CustomAppBarProps = {
  navigation: NavigationHelpers<ParamListBase>;
  route: Route<string>;
  options: { title?: string; headerTitle?: any };
  back: boolean;
  handleActivityUpdate?: (updatedActivity: any) => void; // Add handleActivityUpdate prop
};

const ChironAppBar: React.FC<CustomAppBarProps> = ({
  navigation,
  route,
  options,
  back,
}) => {
  const { state } = useProfile();
  const { state: scheduleState } = useSchedule();
  const tabName = getFocusedRouteNameFromRoute(route);
  const title =
    tabName !== undefined
      ? tabName === "Schedule"
        ? formatDateToCustomFormat(scheduleState.focusDate) // Use state.focusDate if tabName is "Schedule"
        : tabName // Use tabName for other tabs
      : getHeaderTitle(options, route.name);
  const [coachWithAthletesView, setCoachWithAthletesView] = useState(false);

  useEffect(() => {
    if (
      state.profile !== null &&
      isCoach(state.profile) &&
      state.athletes.length > 0
    ) {
      setCoachWithAthletesView(true);
    } else {
      setCoachWithAthletesView(false);
    }
  }, [state.athletes, state.profile]);

  const renderAppBarMenu = () => {
    switch (route.name) {
      case SCREEN_NAMES.ACTIVITY_DETAILS:
        return <ActivityAppBarMenu />;
      case SCREEN_NAMES.LIBRARY_WORKOUT_DETAILS:
        return <LibraryWorkoutAppBarMenu />;
      case SCREEN_NAMES.EVENT_DETAILS:
        return <EventAppBarMenu />;
      default:
        return null;
    }
  };

  return (
    <Appbar.Header>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={title} />
      {coachWithAthletesView &&
        (tabName === SCREEN_NAMES.HOME ||
          tabName === SCREEN_NAMES.SCHEDULE ||
          tabName === SCREEN_NAMES.INSIGHTS) && <CoachViewSelectorChip />}
      {renderAppBarMenu()}
    </Appbar.Header>
  );
};

export default ChironAppBar;
