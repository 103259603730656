import React, { useState } from "react";
import { Chip, Divider, Menu, useTheme } from "react-native-paper";
import { CoachedAthleteProfile } from "../../types/user";
import { filterAcceptedAthletes } from "../utils/coaches";
import { getUserName } from "../utils/profileService";
import { useProfile } from "../../provider/ProfileProvider";
import {
  CoachViewContextTypes,
  ProfileActionTypes,
} from "../../provider/ProfileProvider/types";
import { ICONS } from "../../constants/icons";

interface CoachViewMenuProps {
  anchor: React.ReactNode;
}

const CoachViewSelectorChip: React.FC<CoachViewMenuProps> = ({ anchor }) => {
  const { state, dispatch } = useProfile();
  const theme = useTheme();

  const [menuVisible, setMenuVisible] = useState(false);

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  // onPress, set state.coachView to CoachView.SELF if "My Training" is selected or CoachView.ATHLETE if an athlete is selected
  const setCoachView = (
    type: CoachViewContextTypes.SELF | CoachViewContextTypes.ATHLETE,
    id?: string,
    showLabel?: boolean //defaults to true
  ) => {
    // note ScheduleProvider will automatically fetch the new schedule when coachView changes
    if (type === CoachViewContextTypes.SELF) {
      dispatch({
        type: ProfileActionTypes.SET_COACH_VIEW,
        coachView: { type },
      });
    } else {
      dispatch({
        type: ProfileActionTypes.SET_COACH_VIEW,
        coachView: { type, id: id! },
      });
    }
    closeMenu();
  };

  const getSeletedAthlete = (): CoachedAthleteProfile | undefined => {
    const { coachView } = state;

    if (coachView.type === CoachViewContextTypes.ATHLETE) {
      return state.athletes.find((athlete) => athlete.id === coachView.id);
    }

    return undefined;
  };

  const defaultChip = (
    <Chip
      icon={ICONS.USER_VIEW_CONTEXT}
      // textStyle={theme.fonts.titleLarge}
      mode="outlined"
      onPress={() => openMenu()}
    >
      {state.coachView.type === CoachViewContextTypes.SELF
        ? "My Training"
        : getUserName(getSeletedAthlete())}
    </Chip>
  );

  const anchorInUse = anchor ? anchor : defaultChip;

  return (
    <Menu visible={menuVisible} onDismiss={closeMenu} anchor={anchorInUse}>
      <Menu.Item
        onPress={() => setCoachView(CoachViewContextTypes.SELF)}
        title="My Training"
        leadingIcon="account"
      />
      <Divider />
      {/* map state.athletes into Menu.Items */}
      {filterAcceptedAthletes(state.athletes).length > 0 ? (
        filterAcceptedAthletes(state.athletes).map((athlete, index) => (
          <Menu.Item
            key={index}
            title={getUserName(athlete)}
            onPress={() =>
              setCoachView(CoachViewContextTypes.ATHLETE, athlete.id)
            }
            leadingIcon="account"
          />
        ))
      ) : (
        <Menu.Item title="No athletes yet." />
      )}
    </Menu>
  );
};

export default CoachViewSelectorChip;
