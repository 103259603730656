import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { ProfileAction, ProfileState, CoachViewContextTypes } from "./types";
import profileReducer from "./reducer";
import {
  fetchInitialProfile,
  fetchCoachedAthleteData,
  fetchCoachesData,
} from "./actions";
import { AccountTypes } from "../../types/user";

// Initial state
const initialState: ProfileState = {
  isLoadingProfile: true,
  isLoadingAthletes: true,
  isLoadingCoaches: true,
  fetchWorkoutLibraryRequired: true,
  profile: null,
  error: null,
  athletes: [],
  coaches: [],
  coachView: { type: CoachViewContextTypes.SELF },
};

// Create Context
const ProfileContext = createContext<{
  state: ProfileState;
  dispatch: React.Dispatch<ProfileAction>;
} | null>(null);

// Provider component
export const ProfileProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  useEffect(() => {
    fetchInitialProfile(dispatch);
  }, []);

  useEffect(() => {
    fetchCoachesData(dispatch);
    if (state.profile?.account_type === AccountTypes.COACH) {
      fetchCoachedAthleteData(dispatch);
    }
  }, [state.isLoadingProfile]);

  return (
    <ProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ProfileContext.Provider>
  );
};

// Custom hook for using the Profile context
export function useProfile() {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
}
