import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import EventCard from "./EventCard";
import { EventData } from "../../types/event";
import { SCREEN_NAMES } from "../../constants/screenNames";

const TouchableEventCard = ({
  event,
  navigation,
}: {
  event: EventData;
  navigation: any;
}) => {
  const handleEventPress = (event: EventData) => {
    navigation.navigate(SCREEN_NAMES.EVENT_DETAILS, { event });
  };

  return (
    <TouchableOpacity
      style={styles.eventCardContainer}
      onPress={() => handleEventPress(event)}
    >
      <EventCard event={event} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  eventCardContainer: {
    marginBottom: 8,
    width: "100%",
  },
});

export default TouchableEventCard;
