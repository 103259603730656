import React, { useState } from "react";
import { Button, List, useTheme, Text } from "react-native-paper";
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  Image,
} from "react-native";
import InviteAthleteDialog from "./InviteAthleteDialog";
import { fetchCoachedAthleteData } from "../../provider/ProfileProvider/actions";
import { useProfile } from "../../provider/ProfileProvider";

interface InviteAthleteProps {}

const InviteAthleteButton: React.FC<InviteAthleteProps> = () => {
  const { state, dispatch } = useProfile();
  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => {
    fetchCoachedAthleteData(dispatch); // Fetch the list of athletes
    setVisible(false);
  };

  const handlePress = () => {
    // open the DisconnectDialog component
    showDialog();
  };

  return (
    <View style={styles.container}>
      <Button mode="contained" onPress={handlePress}>
        Invite Athletes
      </Button>
      <InviteAthleteDialog visible={visible} hideDialog={hideDialog} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
  },
});

export default InviteAthleteButton;
