import {
  CoachedAthletes,
  Coaches,
  CoachStatus,
  UserProfile,
} from "../../types/user";

export function hasPendingCoachRequests(coaches: Coaches) {
  if (!Array.isArray(coaches)) {
    return false;
  }
  return coaches.some((coach) => coach.status === CoachStatus.PENDING);
}

export function hasAthletes(athletes: CoachedAthletes) {
  if (!Array.isArray(athletes)) {
    return false;
  }
  return athletes.some((athlete) => athlete.status === CoachStatus.ACCEPTED);
}

export function filterPendingCoaches(coaches: Coaches) {
  return coaches.filter((coach) => coach.status === CoachStatus.PENDING);
}

export function filterAcceptedCoaches(coaches: Coaches) {
  return coaches.filter((coach) => coach.status === CoachStatus.ACCEPTED);
}

// function to return boolean if account type is "coach" based on the user's profile
export function isCoach(profile: UserProfile) {
  if (!profile) {
    return false;
  }
  return profile.account_type === "coach";
}

export const filterAcceptedAthletes = (athletes: CoachedAthletes) => {
  return athletes.filter((athlete) => athlete.status === CoachStatus.ACCEPTED);
};

export const filterPendingAthletes = (athletes: CoachedAthletes) => {
  return athletes.filter((athlete) => athlete.status === CoachStatus.PENDING);
};
