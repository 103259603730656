import React, { useEffect, useState } from "react";
import { View, StyleSheet, Alert, TouchableOpacity } from "react-native";
import {
  Card,
  Icon,
  IconButton,
  Menu,
  TouchableRipple,
} from "react-native-paper";
import { FileObject } from "@supabase/storage-js";
import { ICONS } from "../../constants/icons";
import {
  downloadEventAttachment,
  deleteEventAttachment,
  renameEventAttachment,
  updateCoverImage,
} from "../../api/events";
import { EventData } from "../../types/event";
import DeleteConfirmation from "../utils/DeleteConfirmation";
import RenameFileDialog from "./RenameAttachmentDialog";
import { checkIsImage, checkIsPDF, openDownloadedFile } from "../utils/fileIO";
import ImageDisplayComponent from "../utils/ImageDisplay";
import { get, set } from "lodash";
import { fetchThumbnail } from "../../api/storage";
import { updateScheduleForDates } from "../../provider/ScheduleProvider/actions";
import { useSchedule } from "../../provider/ScheduleProvider";
import { RouteProp, useRoute } from "@react-navigation/native";
import { MainStackParamList, ROUTES } from "../../types/navigation";
import { is } from "date-fns/locale";
import { findEventById } from "../../provider/ScheduleProvider/selectors";

interface AttachmentCardProps {
  file: FileObject;
  eventId: EventData["id"];
  userId: EventData["user_id"];
  setFetchAttachmentList: React.Dispatch<React.SetStateAction<boolean>>;
}

type EventAttachmentsGroupRouteProp = RouteProp<
  MainStackParamList,
  typeof ROUTES.EVENT
>;

const AttachmentCard: React.FC<AttachmentCardProps> = ({
  file,
  eventId,
  userId,
  setFetchAttachmentList,
}) => {
  const { state, dispatch } = useSchedule();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const route = useRoute<EventAttachmentsGroupRouteProp>();
  const [event, setEvent] = useState<EventData | null>(route.params.event);
  const [isCoverImage, setIsCoverImage] = useState<boolean>(false);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const checkIsCoverImage = () => {
    return event ? event.cover_image === file.name : false;
  };

  useEffect(() => {
    const fetchThumbnailUrl = async () => {
      if (file.metadata.mimetype.startsWith("image/")) {
        try {
          const filePath = `${userId}/${eventId}/attachments/${file.name}`;
          const url = await fetchThumbnail("events", filePath, 200, 200);
          setThumbnailUrl(url);
        } catch (error) {
          console.error("Error fetching thumbnail:", error);
        }
      }
    };
    setIsImage(checkIsImage(file));
    if (isImage) {
      fetchThumbnailUrl();
      setIsCoverImage(checkIsCoverImage());
    }
  }, [file, eventId, userId, isImage]);

  useEffect(() => {
    setEvent(findEventById(state.schedule, eventId));
  }, [state.schedule, eventId]);

  const handlePress = async () => {
    try {
      const data = await downloadEventAttachment(eventId, userId, file.name);
      // setImageBlob(data);
      await openDownloadedFile(data, file.name);
    } catch (error) {
      console.error("Error downloading and opening attachment:", error);
    }
  };

  const handleRename = () => {
    closeMenu();
    setShowRenameDialog(true);
  };

  const handleSetAsCover = async () => {
    await updateCoverImage(eventId, userId, file.name);
    if (userId) {
      if (event) {
        await updateScheduleForDates(dispatch, userId, event.event_date);
      }
    }
    setIsCoverImage(true);
    closeMenu();
    console.log("Set as cover image:", file);
  };

  const handleUnsetAsCover = async () => {
    await updateCoverImage(eventId, userId, null);
    if (userId) {
      if (event) {
        await updateScheduleForDates(dispatch, userId, event.event_date);
      }
    }
    setIsCoverImage(false);
    closeMenu();
    console.log("Remove as cover image:", file);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
    setFetchAttachmentList(true);
  };

  const handleConfirmRename = async (newFileName: string) => {
    try {
      await renameEventAttachment(
        eventId,
        userId,
        file.name,
        newFileName,
        isCoverImage
      );
      setFetchAttachmentList(true);
      closeMenu();
      // TODO: this may be a problem if the image is the cover image but dont want to reload entire schedule ...
    } catch (error) {
      console.error("Error renaming attachment:", error);
      Alert.alert("Error", "Failed to rename attachment");
    } finally {
      setShowRenameDialog(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteEventAttachment(eventId, userId, file.name);
      setFetchAttachmentList(true);
      // Update the global state schedule for the event date
      if (userId) {
        if (event) {
          await updateScheduleForDates(dispatch, userId, event.event_date);
        }
      }
      closeMenu();
    } catch (error) {
      console.error("Error deleting attachment:", error);
      Alert.alert("Error", "Failed to delete attachment");
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <TouchableRipple onPress={handlePress}>
      <Card style={styles.card}>
        {isImage && thumbnailUrl && (
          <Card.Cover source={{ uri: thumbnailUrl }} />
        )}
        <Card.Title
          title={file.name}
          titleNumberOfLines={2}
          subtitle={isCoverImage ? "Cover" : ""}
          left={(props) => (
            <Icon
              {...props}
              source={
                isImage
                  ? ICONS.IMAGE
                  : checkIsPDF(file)
                  ? ICONS.PDF
                  : ICONS.FILE
              }
            />
          )}
          right={(props) => (
            <Menu
              visible={menuVisible}
              onDismiss={closeMenu}
              anchor={
                <IconButton {...props} icon={ICONS.MORE} onPress={openMenu} />
              }
            >
              <Menu.Item
                onPress={handleRename}
                title="Rename"
                leadingIcon={ICONS.EDIT}
              />
              {isImage &&
                (isCoverImage ? (
                  <Menu.Item
                    onPress={handleUnsetAsCover}
                    title="Remove as cover image"
                    leadingIcon={ICONS.CHECKBOX_MARKED}
                  />
                ) : (
                  <Menu.Item
                    onPress={handleSetAsCover}
                    title="Set as cover image"
                    leadingIcon={ICONS.CHECKBOX_UNMARKED}
                  />
                ))}
              <Menu.Item
                onPress={handleDelete}
                title="Delete"
                leadingIcon={ICONS.DELETE}
              />
            </Menu>
          )}
        />
        {showRenameDialog && (
          <RenameFileDialog
            visible={showRenameDialog}
            onDismiss={() => setShowRenameDialog(false)}
            onRename={handleConfirmRename}
            initialFileName={file.name}
          />
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmation
            message={`Are you sure you want to delete ${file.name}? This can't be undone.`}
            onConfirm={handleConfirmDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
      </Card>
    </TouchableRipple>
  );
};

const styles = {
  card: {
    marginBottom: 10,
  },
};

export default AttachmentCard;
