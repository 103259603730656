import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, List, Switch, Text, useTheme } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import StravaConnectButton from "../components/Strava/ConnectButton";
import StravaDisconnectButton from "../components/Strava/DisconnectButton";
import StravaConnectionStatusIcon from "../components/Strava/ConnectionStatusIcon";
import Loading from "./utils/Loading";
import { isStravaConnected } from "../components/utils/profileService";
import StravaImportActivitiesButton from "../components/Strava/ImportActivitiesButton";
import { useProfile } from "../provider/ProfileProvider";

export default function ConnectStrava() {
  const { state, dispatch } = useProfile();

  if (state.isLoadingProfile) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      <List.Section>
        <List.Subheader>Strava</List.Subheader>
        <List.Item
          description="Connection status"
          title={
            state.profile?.strava_api_data
              ? state.profile.strava_api_data.connection_status
              : "Unknown"
          }
          right={() => <StravaConnectionStatusIcon />}
        />
        {isStravaConnected(state.profile) ? (
          <>
            <List.Item
              description="Username"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.athlete.username
                  : "Unknown"
              }
            />
            <List.Item
              description="Access token"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.access_token
                  : "Unknown"
              }
            />
            <List.Item
              description="Expiry"
              title={
                state.profile?.strava_api_data
                  ? state.profile.strava_api_data.expires_at
                  : "Unknown"
              }
            />
            <StravaImportActivitiesButton />
            <StravaDisconnectButton />
          </>
        ) : (
          <StravaConnectButton />
        )}
      </List.Section>
    </ScreenWrapper>
  );
}
