import isEmpty from "lodash/isEmpty";
import React, { useCallback } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ScheduledItem, ScheduledItemTypes } from "../../types/schedule";
import TouchableEventCard from "../Event/TouchableEventCard";
import TouchableActivityCard from "./TouchableActivityCard";
import TouchableNoteCard from "../Notes/TouchableNoteCard";

interface ItemProps {
  item: ScheduledItem;
  navigation: any;
}

const AgendaItem = (props: ItemProps) => {
  const { item, navigation } = props;
  if (isEmpty(item)) {
    return (
      <View style={styles.emptyItem}>
        <Text style={styles.emptyItemText}>No Events Planned Today</Text>
      </View>
    );
  }

  // switch between components based on scheduledItemType
  // if scheduledItemType is EVENT, render EventCard
  // if scheduledItemType is WORKOUT, render ActivityCard
  // if scheduledItemType is NOTE, render NoteCard
  switch (item.scheduledItemType) {
    case ScheduledItemTypes.EVENT:
      return <TouchableEventCard event={item} navigation={navigation} />;
    case ScheduledItemTypes.WORKOUT:
      return <TouchableActivityCard activity={item} navigation={navigation} />;
    case ScheduledItemTypes.NOTE:
      return <TouchableNoteCard note={item} navigation={navigation} />;
    default:
      return <TouchableActivityCard activity={item} navigation={navigation} />;
  }
};

export default React.memo(AgendaItem);

const styles = StyleSheet.create({
  item: {
    paddingVertical: 4,
    paddingRight: 10,
    flexDirection: "row",
  },
  itemHourText: {
    color: "black",
  },
  itemDurationText: {
    color: "grey",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  itemTitleText: {
    color: "black",
    marginLeft: 16,
    fontWeight: "bold",
    fontSize: 16,
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
  },
  emptyItemText: {
    color: "lightgrey",
    fontSize: 14,
  },
});
