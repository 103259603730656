import { ActivityData } from "./activity";
import { EventData } from "./event";
import { NoteData } from "./note";

// Type definitions for schedule
// schedule items can be "WORKOUT", "EVENT" or "NOTE"
export enum ScheduledItemTypes {
  WORKOUT = "WORKOUT",
  EVENT = "EVENT",
  NOTE = "NOTE",
}

export type ScheduledWorkout = ActivityData & {
  scheduledItemType: ScheduledItemTypes.WORKOUT;
};

export type ScheduledEvent = EventData & {
  scheduledItemType: ScheduledItemTypes.EVENT;
};

export type ScheduledNote = NoteData & {
  scheduledItemType: ScheduledItemTypes.NOTE;
};

export type ScheduledItem = ScheduledWorkout | ScheduledEvent | ScheduledNote;

export type Schedule =
  | {
      [date: string]: ScheduledItem[];
    }
  | {};
