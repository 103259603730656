import React, { useContext } from "react";
import Navigation from "./src/navigation";
import { AuthProvider } from "./src/provider/AuthProvider";
import { Portal } from "react-native-paper";
import { enGB, registerTranslation } from "react-native-paper-dates";
import ThemeProvider, { useTheme } from "./src/provider/ThemeProvider";
import DumbTestScreen from "./src/screens/DumbTestScreen";
import { getCalendarTheme } from "./src/provider/ThemeProvider/calendarTheme";
import config from "./config";
import { ScheduleProvider } from "./src/provider/ScheduleProvider";
import { ProfileProvider } from "./src/provider/ProfileProvider";

registerTranslation("en-GB", enGB);

export default function App() {
  const images = [
    require("./assets/images/long-logo-purple.png"),
    require("./assets/images/long-logo-white.png"),
  ];
  const { currentTheme } = useTheme();

  return (
    <ThemeProvider>
      <AuthProvider>
        <ProfileProvider>
          <ScheduleProvider>
            {/* this stops portals from rendering at the ThemeProvider and outside of ScheduleProvider */}
            <Portal.Host>
              <Navigation theme={currentTheme} />
              {/* <DumbTestScreen /> */}
            </Portal.Host>
          </ScheduleProvider>
        </ProfileProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
