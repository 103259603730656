import {
  RunActivityType,
  SwimActivityType,
  RideActivityType,
  StrengthActivityType,
  WorkoutActivityType,
  WalkActivityType,
  EllipticalActivityType,
  DeepWaterRunActivityType,
  YogaActivityType,
  RecoveryActivityType,
  MultisportActivityType,
  OtherActivityType,
  ActivityType,
  ActivityStatus,
  TotalType,
  EventActivityType,
  TriathlonActivityType,
} from "../types/activity";

// TODO: change over the erefrences to this array and use the types
export const types: ActivityType[] = [
  RunActivityType,
  SwimActivityType,
  RideActivityType,
  StrengthActivityType,
  WorkoutActivityType,
  WalkActivityType,
  EllipticalActivityType,
  DeepWaterRunActivityType,
  YogaActivityType,
  RecoveryActivityType,
  TriathlonActivityType,
  MultisportActivityType,
  OtherActivityType,
];

export const eventActivityTypes: EventActivityType[] = [
  RunActivityType,
  SwimActivityType,
  RideActivityType,
  WalkActivityType,
  TriathlonActivityType,
  MultisportActivityType,
  OtherActivityType,
];

export const activityTypes: ActivityType[] = types;

// export const activityStatuses: ActivityStatus[] = [
//   { label: "Completed", value: "COMPLETED", color: "green" },
//   { label: "Planned", value: "PLANNED", color: "grey" },
//   { label: "Missed", value: "MISSED", color: "red" },
// ];

export const activityStatuses: Record<string, ActivityStatus> = {
  completed: {
    label: "Completed",
    totalKey: {
      count: "total_count",
      distance: "total_distance",
      duration: "total_time",
    },
    color: "#3dcd58",
  },
  planned: {
    label: "Planned",
    totalKey: {
      count: "total_count_planned_incomplete",
      distance: "total_distance_planned_incomplete",
      duration: "total_time_planned_incomplete",
    },
    color: "#8293a6",
  },
  missed: {
    label: "Missed",
    totalKey: {
      count: "total_count_missed",
      distance: "total_distance_missed",
      duration: "total_time_missed",
    },
    color: "#dc0a0a",
  },
};
