import React, { useState } from "react";
import { Chip, Divider, Menu, useTheme } from "react-native-paper";
import { CoachedAthleteProfile } from "../../types/user";
import { filterAcceptedAthletes } from "../utils/coaches";
import { getUserName } from "../utils/profileService";
import { useProfile } from "../../provider/ProfileProvider";
import {
  CoachViewContextTypes,
  ProfileActionTypes,
} from "../../provider/ProfileProvider/types";
import { ICONS } from "../../constants/icons";

interface CoachViewSelectorMenuItems {
  closeMenu: () => void;
}

const CoachViewSelectorMenuItems: React.FC<CoachViewSelectorMenuItems> = ({
  closeMenu,
}) => {
  const { state, dispatch } = useProfile();

  // onPress, set state.coachView to CoachView.SELF if "My Training" is selected or CoachView.ATHLETE if an athlete is selected
  const setCoachView = (
    type: CoachViewContextTypes.SELF | CoachViewContextTypes.ATHLETE,
    id?: string,
    showLabel?: boolean //defaults to true
  ) => {
    // note ScheduleProvider will automatically fetch the new schedule when coachView changes
    if (type === CoachViewContextTypes.SELF) {
      dispatch({
        type: ProfileActionTypes.SET_COACH_VIEW,
        coachView: { type },
      });
    } else {
      dispatch({
        type: ProfileActionTypes.SET_COACH_VIEW,
        coachView: { type, id: id! },
      });
    }
    closeMenu();
  };

  return (
    <>
      <Menu.Item
        onPress={() => setCoachView(CoachViewContextTypes.SELF)}
        title="My Training"
        leadingIcon="account"
      />
      <Divider />
      {/* map state.athletes into Menu.Items */}
      {filterAcceptedAthletes(state.athletes).length > 0 ? (
        filterAcceptedAthletes(state.athletes).map((athlete, index) => (
          <Menu.Item
            key={index}
            title={getUserName(athlete)}
            onPress={() =>
              setCoachView(CoachViewContextTypes.ATHLETE, athlete.id)
            }
            leadingIcon="account"
          />
        ))
      ) : (
        <Menu.Item title="No athletes yet." />
      )}
    </>
  );
};

export default CoachViewSelectorMenuItems;
