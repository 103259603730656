import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Avatar,
  Text,
  Icon,
  useTheme,
  TouchableRipple,
  Menu,
} from "react-native-paper";
import { getUserName, getUserInitials } from "../utils/profileService";
import CoachViewSelectorChip from "../Coaches/CoachViewSelectorChip";
import { CoachedAthleteProfile, UserProfile } from "../../types/user";
import { ICONS } from "../../constants/icons";
import { useProfile } from "../../provider/ProfileProvider";
import { isCoach } from "../utils/coaches";
import CoachViewSelectorMenuItems from "../Coaches/CoachViewSelectorMenuItems";

interface UserProfileCardProps {
  profile: UserProfile | CoachedAthleteProfile | null | undefined;
}

interface CoachAvatarProps {
  profile: UserProfile | CoachedAthleteProfile;
  openMenu: () => void;
}

const CoachAvatar: React.FC<CoachAvatarProps> = ({ profile }) => {
  const theme = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <Menu
      visible={menuVisible}
      onDismiss={closeMenu}
      anchor={
        <TouchableRipple onPress={openMenu}>
          <View style={styles.avatarContainer}>
            <Avatar.Text
              label={getUserInitials(profile)}
              size={48}
              style={[
                styles.avatar,
                {
                  borderColor: theme.colors.secondary,
                  borderWidth: 1,
                },
              ]}
            />
            <View style={styles.iconContainer}>
              <Avatar.Icon
                icon={ICONS.USER_VIEW_CONTEXT}
                size={24}
                color={theme.colors.outline}
                style={[
                  styles.coachChangeUserViewContextIcon,
                  {
                    backgroundColor: theme.colors.surface,
                    borderColor: theme.colors.outline,
                  },
                ]}
              />
            </View>
          </View>
        </TouchableRipple>
      }
    >
      <CoachViewSelectorMenuItems closeMenu={closeMenu} />
    </Menu>
  );
};

const UserProfileCard: React.FC<UserProfileCardProps> = ({ profile }) => {
  const theme = useTheme();
  const { state } = useProfile();
  const isUserCoach = state.profile ? isCoach(state.profile) : false;
  return (
    <View style={styles.container}>
      {isUserCoach ? (
        <CoachViewSelectorChip
          anchor={<CoachAvatar profile={profile} openMenu={() => {}} />}
        />
      ) : (
        <View style={styles.avatarContainer}>
          <Avatar.Text
            label={getUserInitials(profile)}
            size={48}
            style={[
              styles.avatar,
              isUserCoach && {
                borderColor: theme.colors.secondary,
                borderWidth: 1,
              },
            ]}
          />
        </View>
      )}
      <Text style={styles.username} variant="titleLarge">
        {getUserName(profile)}
      </Text>
      <Text style={styles.bio} variant="labelLarge">
        {profile?.bio ? profile.bio : "Bio not set."}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
  },
  avatarContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    position: "absolute",
    bottom: 0,
    right: -8,
  },
  avatar: {
    marginBottom: 8,
  },
  coachChangeUserViewContextIcon: { borderWidth: 1 },
  username: {
    // fontSize: 24,
    // fontWeight: "bold",
    // marginBottom: 8,
  },
  location: {
    fontSize: 16,
    color: "gray",
    marginBottom: 8,
  },
  bio: {
    // fontSize: 16,
    textAlign: "center",
  },
});

export default UserProfileCard;
