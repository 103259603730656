import React, { useState } from "react";
import {
  Portal,
  Dialog,
  TextInput,
  HelperText,
  Button,
} from "react-native-paper";

interface RenameFileDialogProps {
  visible: boolean;
  onDismiss: () => void;
  onRename: (newFileName: string) => void;
  initialFileName: string;
}

const RenameFileDialog: React.FC<RenameFileDialogProps> = ({
  visible,
  onDismiss,
  onRename,
  initialFileName,
}) => {
  const [newFileName, setNewFileName] = useState<string>(initialFileName);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  const handleCancel = () => {
    setNewFileName(initialFileName);
    setHasErrors(false);
    setErrorText("");
    onDismiss();
  };

  const confirmRename = () => {
    if (newFileName.trim() === "") {
      setHasErrors(true);
      setErrorText("File name cannot be empty.");
      return;
    }
    onRename(newFileName);
    onDismiss();
  };

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={handleCancel}>
        <Dialog.Title>Rename file</Dialog.Title>
        <Dialog.Content>
          <TextInput
            label="File name"
            value={newFileName}
            onChangeText={(text) => setNewFileName(text)}
            multiline
          />
          <HelperText type="error" visible={hasErrors}>
            {errorText}
          </HelperText>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={handleCancel}>Cancel</Button>
          <Button onPress={confirmRename}>Rename</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default RenameFileDialog;
