import { supabase } from "../initSupabase";

export const downloadFromStorage = async (
  bucket: string,
  path: string,
  fileName: string
) => {
  try {
    const { data, error } = await supabase.storage.from(bucket).download(path);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    throw error;
    console.error("Error downloading or opening file:", error);
  }
};

// Function to fetch a thumbnail from Supabase storage
export const fetchThumbnail = async (
  bucket: string,
  path: string,
  width: number,
  height: number
) => {
  try {
    // supbase Image Resizing is currently enabled for Pro Plan and above.
    // const { data, error } = await supabase.storage.from(bucket).download(path, {
    //   transform: {
    //     width,
    //     height,
    //     resize: "cover",
    //   },
    // });
    const { data, error } = await supabase.storage.from(bucket).download(path);

    if (error) throw error;

    const url = URL.createObjectURL(data);
    return url;
  } catch (error) {
    console.error("Error fetching thumbnail:", error);
    throw error;
  }
};
