import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { List, IconButton, Badge } from "react-native-paper";
import { ICONS } from "../../constants/icons";
import UploadAttachmentDialog from "./UploadAttachmentDialog"; // Import the UploadAttachmentDialog component
import { EventData } from "../../types/event";
import { FileObject } from "@supabase/storage-js";
import { fetchEventAttachments } from "../../api/events";
import { View } from "react-native";
import AttachmentCard from "./EventAttachmentCard";

interface UploadAttachmentsGroupProps {
  eventId: EventData["id"];
  userId: EventData["user_id"];
}

const UploadAttachmentsGroup: React.FC<UploadAttachmentsGroupProps> = ({
  eventId,
  userId,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [attachments, setAttachments] = useState<FileObject[]>([]);
  const [fetchAttachmentList, setFetchAttachmentList] = useState<boolean>(true);

  const showDialog = () => setDialogVisible(true);
  const hideDialog = () => setDialogVisible(false);

  const handleUploadAttachmentPress = () => {
    showDialog();
  };

  //   useEffect to fetch attachments from the server
  useEffect(() => {
    // Fetch attachments from the server
    const fetchAttachments = async () => {
      try {
        // Make a request to the server to fetch attachments
        const fetchedAttachments = await fetchEventAttachments(eventId, userId);
        // Update the attachments state with the response
        setAttachments(fetchedAttachments);
      } catch (error) {
        console.error("Error fetching attachments:", error);
      }
    };
    if (fetchAttachmentList) {
      fetchAttachments();
      setFetchAttachmentList(false);
    }
  }, [fetchAttachmentList]);

  return (
    <>
      <UploadAttachmentDialog
        visible={dialogVisible}
        onDismiss={hideDialog}
        eventId={eventId}
        userId={userId}
        setFetchAttachmentList={setFetchAttachmentList}
      />
      <List.Accordion
        title="Attachments"
        id="2"
        left={(props) => (
          <View style={styles.iconWithBadge}>
            <List.Icon {...props} icon={ICONS.ATTACHMENT} />
            <Badge
              size={20}
              visible={attachments.length > 0}
              style={styles.badge}
            >
              {attachments.length.toString()}
            </Badge>
          </View>
        )}
        style={styles.accordion}
      >
        <List.Item
          title="Upload an attachment"
          right={() => <IconButton icon={ICONS.ADD} />}
          onPress={handleUploadAttachmentPress}
        />
        {/* <View style={styles.cardContainer}> */}
        {attachments.map((file, index) => (
          <AttachmentCard
            key={index}
            file={file}
            eventId={eventId}
            userId={userId}
            setFetchAttachmentList={setFetchAttachmentList}
          />
        ))}
        {/* </View> */}
      </List.Accordion>
    </>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    // padding: 10,
  },
  iconWithBadge: { flexDirection: "row", flexWrap: "wrap" },
  badge: {
    position: "absolute",
    top: -2,
    right: -8,
  },
  accordion: {
    // paddingRight: 30, // Remove left padding from the List.Accordion
  },
});

export default UploadAttachmentsGroup;
